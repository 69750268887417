import { BeneficiaireEntity } from '../../data/entity/beneficiaire.entity';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { BeneficiaireService } from "src/app/services/beneficiaire.service";
import { DashboardUseCase } from "src/app/usecases/dashboard-use-case";
import { ProfessionelService } from "src/app/services/professionel.service";

@Component({
  selector: "app-beneficiaire-status",
  templateUrl: "./beneficiaire-status.component.html",
  styleUrls: ["./beneficiaire-status.component.css"],
})
export class BeneficiaireStatusComponent implements OnInit {
  beneficiaires: BeneficiaireEntity[];
  isLoadingBeneficiaires: boolean = false;

  constructor(
    // private appService: AppService,
    private beneficiaireService: BeneficiaireService,
    private dashboardUseCase: DashboardUseCase,
    private professionelService: ProfessionelService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isLoadingBeneficiaires = true;

    if (this.professionelService.getProfessionnel().value == null) {
      this.professionelService.getLocalStorageProfessionnel();
    }

    if (this.beneficiaireService.getBeneficiaires().value == null) {
      this.beneficiaireService.getLocalStorageBeneficiaires();
    }

    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.beneficiaires = this.beneficiaireService.getBeneficiairesByStatus(
        paramMap.get("status")
      );
      this.isLoadingBeneficiaires = false;
    });

    // this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
    //     this.beneficiaireService
    //       .getBeneficiaires()
    //       .subscribe((_data: BeneficiaireEntity[]) => {
    //         this.beneficiaires = _data.filter(
    //           (element) => element.status == paramMap.get("status")
    //         );
    //       });
    //   });
  }
}

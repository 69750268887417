import { AideModel } from "./../model/aide.model";
import { HelpRDS } from "./../datasource/remote-datasource/help-rds";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: "root",
})
export class HelpRepository {
  constructor(private helpRDS: HelpRDS) {}

  sendData(help: AideModel): Observable<AideModel> {
    return this.helpRDS.sendData(help);
  }
}

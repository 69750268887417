<header>
  <div class="box-brand">
    <img
      src="../../../../assets/img/HATWORK LOGO NOUVEAU_Hatwork Logo.png"
      alt="logo"
      class="brand"
    />
  </div>
  <div class="nav">
    <a class="user"
      ><i class="fa fa-user-circle" aria-hidden="true"></i> {{ fullName }}
    </a>
  </div>
</header>

<aside>
  <nav aria-label="nav-top" class="first-nav">
    <a
      (click)="redirect('/dashboard')"
      [ngClass]="{ active: selectedItem == 1 }"
      ><i class="fa fa-th-large" aria-hidden="true"></i> Dashboard</a
    >
    <a
      (click)="redirect('/attribution')"
      [ngClass]="{ active: selectedItem == 2 }"
      ><i class="fa fa-th-list" aria-hidden="true"></i> Commandes</a
    >
    <!-- <a
      (click)="redirect('/payment/history')"
      [ngClass]="{ active: selectedItem == 3 }"
      ><i class="fa fa-file-pdf-o" aria-hidden="true"></i> Factures</a
    > -->
    <a
      (click)="redirect('/beneficiaire')"
      [ngClass]="{ active: selectedItem == 5 }"
      ><i class="fa fa-address-book-o" aria-hidden="true"></i> Bénéficiaires</a
    >
    <!-- <a
      (click)="redirect('/note/frais')"
      [ngClass]="{ active: selectedItem == 4 }"
      ><i class="fa fa-file-text" aria-hidden="true"></i> Notes de frais</a
    > -->
  </nav>
  <nav aria-label="nav-bottom" class="second-nav">
    <a (click)="redirect('/aide')" [ngClass]="{ active: selectedItem == 6 }"
      ><i class="fa fa-question-circle" aria-hidden="true"></i> Aide</a
    >
    <a class="logout" (click)="logout()"
      ><i class="fa fa-power-off" aria-hidden="true"></i> Déconnexion</a
    >
  </nav>
</aside>

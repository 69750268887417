import { element } from "protractor";
import { BeneficiaireEntity } from "../data/entity/beneficiaire.entity";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BeneficiaireService {
  private beneficiaires: BehaviorSubject<BeneficiaireEntity[]> =
    new BehaviorSubject<BeneficiaireEntity[]>(null);

  constructor() {}

  addBeneficiaire(beneficiaire: BeneficiaireEntity): void {
    this.beneficiaires.value.push(beneficiaire);
  }

  addBeneficiaires(beneficiaires: BeneficiaireEntity[]): void {
    this.beneficiaires.next(beneficiaires);
  }

  getBeneficiairesByStatus(status: string): BeneficiaireEntity[] {
    return this.beneficiaires.value.filter(
      (element: BeneficiaireEntity) => element.status == status
    );
  }

  getBeneficiairesByStatusBehaviorSubject(
    status: string
  ): BeneficiaireEntity[] {
    return this.beneficiaires.value.filter(
      (element: BeneficiaireEntity) => element.status == status
    );
  }

  getLocalStorageBeneficiaires() {
    const beneficiaires = JSON.parse(localStorage.getItem("beneficiaires"));
    this.beneficiaires.next(beneficiaires);
  }

  getBeneficiaires(): BehaviorSubject<BeneficiaireEntity[]> {
    return this.beneficiaires;
  }
}

<app-header></app-header>
<main>
  <app-navigation [selectedItem]="5"></app-navigation>
  <section>
    <div class="title"><h1>Hatwork Account Manager</h1></div>
    <div class="other-body">
      <div class="box-loading" *ngIf="isLoadingBeneficiaires">
        <mat-spinner
          mode="indeterminate"
          strokeWidth="5"
          [diameter]="50"
        ></mat-spinner>
      </div>
      <table *ngIf="!isLoadingBeneficiaires">
        <thead>
          <!-- <th>Id</th> -->
          <th>Nom</th>
          <th>Prenom(s)</th>
          <th>Email</th>
          <th>Statut</th>
        </thead>
        <tbody>
          <tr *ngFor="let beneficiaire of beneficiaires">
            <!-- <td>{{ beneficiaire.id.substring(0, 5) }}...</td> -->
            <td>{{ beneficiaire.nom }}</td>
            <td>{{ beneficiaire.prenom }}</td>
            <td>{{ beneficiaire.email }}</td>
            <td *ngIf="beneficiaire.status != 'activate'">
              <i class="fa fa-check-circle inactive" aria-hidden="true"></i>
            </td>
            <td *ngIf="beneficiaire.status == 'activate'">
              <i class="fa fa-check-circle active" aria-hidden="true"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</main>

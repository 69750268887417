<app-header></app-header>
<main>
  <app-navigation [selectedItem]="2"></app-navigation>
  <section class="section">
    <div class="title"><h1>Hatwork Account Manager</h1></div>
    <div
      *ngIf="isLoading"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      "
    >
      <mat-spinner
        mode="indeterminate"
        strokeWidth="5"
        [diameter]="50"
      ></mat-spinner>
    </div>
    <div
      *ngIf="!isLoading"
      class="other-body"
      style="flex-direction: column; justify-content: flex-start"
    >
      <div style="display: flex; width: 100%; justify-content: space-between">
        <div>
          <button
            type="button"
            class="button"
            pButton
            icon="pi pi-plus-circle"
            label="Creer une commande"
            (click)="createNew()"
          ></button>
        </div>
      </div>
      <div style="margin-top: 10px">
        <p-table
          [value]="attributions"
          dataKey="id"
          #dt1
          [showCurrentPageReport]="true"
          [rowHover]="true"
          [globalFilterFields]="['nom', 'prenoms', 'email']"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Date</th>
              <th>N° Commande</th>
              <th>Montant</th>
              <th>Status</th>
              <th>Justificatifs</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-attribution>
            <tr>
              <td>
                {{ attribution.date | date : "dd/MM/yyyy" }}
              </td>
              <td>
                {{ attribution.numero }}
              </td>
              <td>
                {{ attribution.montantTotal | formatNumber }}
              </td>
              <td>
                <a class="download">
                  <i
                    *ngIf="attribution.factureStatus == 'pending'"
                    class="fa fa-clock-o"
                    aria-hidden="true"
                  ></i>
                  <i
                    *ngIf="attribution.factureStatus == 'failed'"
                    class="fa fa-times-circle-o"
                    aria-hidden="true"
                  ></i>
                  <i
                    *ngIf="attribution.factureStatus == 'activate'"
                    class="fa fa-check-circle-o csv"
                    aria-hidden="true"
                  ></i>
                </a>
              </td>
              <td>
                <a class="download">
                  <i
                    class="fa fa-file-text-o csv"
                    (click)="downloadFile(attribution.csvUrl)"
                    aria-hidden="true"
                  ></i>
                </a>
                <a style="margin-left: 15px" class="download">
                  <i
                    *ngIf="attribution.factureStatus == 'activate'"
                    (click)="downloadFile(attribution.factureUrl)"
                    class="fa fa-file-pdf-o pdf"
                    aria-hidden="true"
                  ></i>
                </a>
              </td>
              <!-- <td>
                <a class="download" (click)="createFromOld()">
                  <i class="fa fa-rotate-right" aria-hidden="true"></i>
                </a>
              </td> -->
            </tr>
          </ng-template>
        </p-table>
      </div>
      <!-- <table style="width: 100%">
        <thead>
          <th>Date</th>
          <th>N° Commande</th>
          <th>Montant</th>
          <th>Status</th>
        </thead>
        <tbody *ngFor="let attribution of attributions">
          <tr>
            <td>{{ attribution.date | date: "dd/MM/yyyy" }}</td>
            <td>{{ attribution.numero }}</td>
            <td>{{ attribution.montant | formatNumber }}</td>
            <td>
              {{
                "En cours"
              }}
            </td>
            <td>
              <a class="download" (click)="slide(attribution.numero)">
                <i class="fa fa-caret-down" aria-hidden="true"></i>
              </a>
            </td>
            <td>
              <a class="download" (click)="downloadCsv(attribution)">
                <i class="fa fa-file-text-o csv" aria-hidden="true"></i>
              </a>
            </td>
            <td>
              <a class="download" (click)="createFromOld()">
                <i class="fa fa-rotate-right" aria-hidden="true"></i>
              </a>
            </td>
          </tr>
          <tr
            *ngFor="let step of attribution.steps"
            [ngClass]="{
              step: true,
              grise: step.isValid !== true,
              visible: selectedItem == attribution.numero,
              invisible: selectedItem !== attribution.numero
            }"
          >
            <td class="first-column">{{ step.name }}</td>
            <td class="second-column">
              <i class="fa fa-check-circle" aria-hidden="true"></i>
            </td>
          </tr>
        </tbody>
      </table> -->
    </div>
  </section>
</main>

<app-header></app-header>
<main>
  <app-navigation [selectedItem]="6"></app-navigation>
  <section class="section">
    <div class="title"><h1>Hatwork Account Manager</h1></div>
    <div class="other-body">
      <div class="container" [style]="{width: '100%'}">
        <div *ngIf="isSendDataSuccessNotification">
          <!-- <div class="alert alert-success">Message envoyé avec succès...</div> -->
          <p-messages
            [(value)]="messages"
            [enableService]="false"
            [closable]="false"
          ></p-messages>
        </div>
        <div *ngIf="isSendDataFailedNotification">
          <!-- <div class="alert alert-failed">Message non envoyé...</div> -->
          <p-messages
            [(value)]="messages"
            [enableService]="false"
            [closable]="false"
          ></p-messages>
        </div>
        <form class="container" [formGroup]="formAide">
          <div>
            <label for="email">Email</label>
            <span
              *ngIf="
                formAide.controls['email'].invalid &&
                formAide.controls['email'].touched
              "
              class="alert alert-danger"
            >
              Obligatoire *
            </span>
            <input
              type="text"
              id="email"
              name="email"
              formControlName="email"
            />
          </div>

          <div>
            <label for="organisation">Organisation</label>
            <span
              *ngIf="
                formAide.controls['organisation'].invalid &&
                formAide.controls['organisation'].touched
              "
              class="alert alert-danger"
            >
              Obligatoire *
            </span>
            <input
              type="text"
              id="organisation"
              name="organisation"
              formControlName="organisation"
            />
          </div>

          <div>
            <label for="sujet">Sujet</label>
            <span
              *ngIf="
                formAide.controls['sujet'].invalid &&
                formAide.controls['sujet'].touched
              "
              class="alert alert-danger"
            >
              Obligatoire *
            </span>
            <input
              type="text"
              id="sujet"
              name="sujet"
              formControlName="sujet"
              #subject
              required
            />
          </div>

          <div>
            <label for="description">Description</label>
            <span
              *ngIf="
                formAide.controls['description'].invalid &&
                formAide.controls['description'].touched
              "
              class="alert alert-danger"
            >
              Obligatoire *
            </span>
            <textarea
              id="description"
              name="description"
              required
              formControlName="description"
              #description
              placeholder="Écrivez ici.."
              style="height: 200px"
            ></textarea>
          </div>

          <input
            *ngIf="!isLoadingSendData"
            type="button"
            value="Valider"
            class="btn"
            (click)="submitForm()"
          />
          <mat-spinner
            *ngIf="isLoadingSendData"
            mode="indeterminate"
            strokeWidth="5"
            [diameter]="50"
          ></mat-spinner>
        </form>
      </div>
    </div>
  </section>
</main>

import { BeneficiaireEntity } from "../data/entity/beneficiaire.entity";
import { Observable } from "rxjs";
import { BeneficiaireModel } from "../data/model/beneficiaire.model";
import { BeneficiaireService } from "../services/beneficiaire.service";
import { DashboardRepository } from "../data/repository/dashboard-repository";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DashboardUseCase {
  constructor(
    private dashboardRepository: DashboardRepository,
    private beneficiaireService: BeneficiaireService
  ) {}

  execute(organisationId: string): Observable<BeneficiaireModel[]> {
    return this.dashboardRepository.getData(organisationId).pipe(
      tap((beneficiaireModels: BeneficiaireModel[]) => {
        console.log("beneficiaireModels");
        console.log(beneficiaireModels);
        let beneficiairesEntities: BeneficiaireEntity[] = [];
        beneficiaireModels.forEach((beneficiaireModel: BeneficiaireModel) => {
          let beneficiaireEntity =
            BeneficiaireEntity.toBeneficiaireEntity(beneficiaireModel);
          beneficiairesEntities.push(beneficiaireEntity);
        });
        // localStorage.setItem(
        //   "beneficiaires",
        //   JSON.stringify(beneficiairesEntities)
        // );
        this.beneficiaireService.addBeneficiaires(beneficiairesEntities);
      })
    );
  }
}

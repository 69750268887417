import { ProfessionelEntity } from "../data/entity/professionnel.entity";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ProfessionelService {
  private professionnel: BehaviorSubject<ProfessionelEntity> =
    new BehaviorSubject<ProfessionelEntity>(null);

  constructor() {}

  setProfessionnel(prof: ProfessionelEntity): void {
    this.professionnel.next(prof);
  }

  getProfessionnel(): BehaviorSubject<ProfessionelEntity> {
    return this.professionnel;
  }

  getLocalStorageProfessionnel() {
    const professionel = JSON.parse(localStorage.getItem("professionnel"));
    this.professionnel.next(professionel);
  }
}

import { UserModel } from "../model/user.model";

export class ProfessionelEntity {
  professionnelId: string;
  firstName: string;
  lastName: string;
  email: string;
  organisationId: string;
  roleName: string;
  status: string;

  constructor(
    professionnelId: string,
    firstName: string,
    lastName: string,
    email: string,
    organisationId: string,
    roleName: string,
    status: string
  ) {
    this.professionnelId = professionnelId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.organisationId = organisationId;
    this.roleName = roleName;
    this.status = status;
  }

  static toProfessionelEntity(param: UserModel): ProfessionelEntity {
    return {
      professionnelId: param.professionnel.professionnelId,
      firstName: param.professionnel.firstName,
      lastName: param.professionnel.lastName,
      email: param.professionnel.email,
      organisationId: param.professionnel.organisationId,
      roleName: param.professionnel.roleName,
      status: param.professionnel.status,
    };
  }
}

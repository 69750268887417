import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatNumber",
})
export class FormatNumberPipe implements PipeTransform {
  transform(price: any): any {
    // if (price.length > 3) {
    //   var reg = new RegExp("B(?=(d{3})+(?!d)");
    //   price = price.replace(reg, "$1");
    // }
    // return price;
    if (price.toString().length > 3) {
      price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return price;
  }
}

export class BeneficiaireTable {
  public beneficiaireId: string;
  public beneficiaireEmail: string;
  public civilite: string;
  public walletTrId: string;
  public organisationId: string;
  public beneficiairePassword: string;
  public beneficiaireStatus: string;
  public beneficiaireNom: string;
  public beneficiairePrenom: string;
  public beneficiaireNumeroTelephone: string;
  public beneficiaireDateNaissance: string;

  constructor(
    organisationId: string,
    civilite: string,
    beneficiaireNom: string,
    beneficiairePrenom: string,
    beneficiaireEmail: string,
    beneficiaireDateNaissance: string
  ) {
    this.organisationId = organisationId;
    this.civilite = civilite;
    this.beneficiaireNom = beneficiaireNom;
    this.beneficiairePrenom = beneficiairePrenom;
    this.beneficiaireEmail = beneficiaireEmail;
    this.beneficiaireDateNaissance = beneficiaireDateNaissance;
  }
}

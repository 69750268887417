import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BeneficiaireTable } from "../../../../data/model/beneficiaireTable.model";
import { BeneficiaireUseCase } from "../../../../usecases/beneficiaire-use-case";
import { ConfirmationService, MessageService } from "primeng/api";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  checkIfBeneficiaireIsInTheOrganisation,
  checkIfUserIsMineur,
} from "../../../../helpers/helpers";
import { BeneficiaireEntity } from "../../../../data/entity/beneficiaire.entity";
import { DashboardUseCase } from "../../../../usecases/dashboard-use-case";
import { ProfessionelService } from "../../../../services/professionel.service";
import { BeneficiaireService } from "../../../../services/beneficiaire.service";

@Component({
  selector: "app-one-beneficiaire-popup",
  templateUrl: "./one-beneficiaire-popup.component.html",
  styleUrls: ["./one-beneficiaire-popup.component.css"],
})
export class OneBeneficiairePopupComponent implements OnInit {
  erreurServeur = "";
  formGroup: FormGroup;
  beneficiaires: BeneficiaireEntity[];
  donneesForm: BeneficiaireTable[] = [];
  isSavingBeneficiaires: boolean = false;
  regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  regex_date = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;

  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private dashboardUseCase: DashboardUseCase,
    private beneficiaireUseCase: BeneficiaireUseCase,
    private confirmationService: ConfirmationService,
    private professionelService: ProfessionelService,
    private beneficiaireService: BeneficiaireService
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      civilite: ["", Validators.required],
      nom: ["", Validators.required],
      prenoms: ["", Validators.required],
      // date_naissance: [
      //   "",
      //   Validators.compose([
      //     Validators.required,
      //     Validators.pattern(this.regex_date),
      //     this.MineurValidator,
      //   ]),
      // ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.regex),
        ]),
      ],
    });
  }

  MineurValidator(control: AbstractControl) {
    let isMineur;

    if (!control.value) {
      isMineur = null;
    } else {
      isMineur = checkIfUserIsMineur(control.value as Date) < 15;
    }

    return isMineur
      ? { mineurError: "Ce bénéficiaire est un mineur de moins de 15 ans" }
      : null;
  }

  handleValider(closeMethod: string) {
    this.erreurServeur = "";
    this.refreshList();

    if (this?.formGroup.valid) {
      let organisation = JSON.parse(localStorage.getItem("organisation"));
      this.donneesForm = [
        new BeneficiaireTable(
          organisation.organisationId,
          this.formGroup.controls["civilite"].value,
          this.formGroup.controls["nom"].value,
          this.formGroup.controls["prenoms"].value,
          this.formGroup.controls["email"].value,
          null
        ),
      ];

      if (
        checkIfBeneficiaireIsInTheOrganisation(
          this.beneficiaires,
          this.donneesForm
        ) === "user_found"
      ) {
        this.formGroup.controls["email"].setErrors({
          alreadyExist: "error_user_already_exist",
        });
      } else {
        this.confirmationService.confirm({
          message: "Vous allez ajouter un bénéficiaire, veuillez confirmer",
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          key: "confirmSaveAllDialog",
          accept: () => {
            this.isSavingBeneficiaires = true;

            this.beneficiaireUseCase.execute(this.donneesForm).subscribe(
              (response) => {
                console.log(">>> response status <<<");
                console.log(response);

                this.isSavingBeneficiaires = false;
                if (response.status) {
                  this.refreshList();

                  if (closeMethod === "AndClose") {
                    this.ref.close({
                      type: "success",
                      message: "Vous avez ajouté un bénéficiaire avec succès",
                      summary: "Success",
                    });
                  } else {
                    this.messageService.add({
                      key: "success_beneficiaire",
                      severity: "success",
                      summary: "Success",
                      detail: "Vous avez ajouté un bénéficiaire avec succès",
                    });
                    this.formGroup.reset();
                  }
                }
              },
              (data) => {
                this.isSavingBeneficiaires = false;
                let erreurMessage: string;
                if (data.status == 401) {
                  erreurMessage = "Votre session a expiré...";
                } else if (data.status == 409) {
                  erreurMessage =
                    "Cet email bénéficiaire est déjà rattaché à une autre entreprise";
                } else {
                  erreurMessage = data.statusText;
                }

                this.erreurServeur = erreurMessage;
              }
            );
          },
          reject: () => {},
        });
      }
    } else {
      this.validateAllFormFields(this.formGroup);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  refreshList() {
    this.dashboardUseCase
      .execute(this.professionelService.getProfessionnel().value.organisationId)
      .subscribe(
        (_data) => {
          console.log("refreshing", _data);
        },
        (err: any) => {
          console.log(err);
        }
      );

    this.beneficiaireService
      .getBeneficiaires()
      .subscribe((_data: BeneficiaireEntity[]) => {
        this.beneficiaires = _data;
      });
  }
}

import { BeneficiaireModel } from '../model/beneficiaire.model';
import { Observable } from "rxjs";
import { DashboardRDS } from '../datasource/remote-datasource/dashboard-rds';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DashboardRepository {
  constructor(private dashboardRDS: DashboardRDS) {}

  getData(organisationId: string): Observable<BeneficiaireModel[]> {
    return this.dashboardRDS.getData(organisationId);
  }
}

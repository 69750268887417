import { HttpHeaders } from "@angular/common/http";
export const conf = {
  // samaFils: "http://localhost:8585",
  // ipAddr: "http://194.163.138.23:8055",
  ipAddr: "https://painaveclait.link:61015",
  app: "professionnel",
  // ipAddrTest: "http://localhost:8055",
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
  headersWithToken: new HttpHeaders({
    "Content-Type": "application/json",
    Authorization: "",
  }),
};

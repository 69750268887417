import { UserModel } from "./../../model/user.model";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { conf } from "src/app/config/config";

@Injectable({
  providedIn: "root",
})
export class LoginRDS {
  constructor(private http: HttpClient) {}

  login(email: string, password: string): Observable<UserModel> {
    return this.http.post<UserModel>(
      conf.ipAddr + "/login/v1/login/professionnel",
      { email: email, password: password, app: conf.app },
      { headers: conf.headers }
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BeneficiaireTable } from "../../../../data/model/beneficiaireTable.model";
import { BeneficiaireUseCase } from "../../../../usecases/beneficiaire-use-case";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-loaded-beneficiaire-popup",
  templateUrl: "./loaded-beneficiaire-popup.component.html",
  styleUrls: ["../../beneficiaire.component.css"],
})
export class LoadedBeneficiairePopupComponent implements OnInit {
  beneficiaires: any[];
  public isSavingBeneficiaires: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private beneficiaireUseCase: BeneficiaireUseCase,
    private confirmationService: ConfirmationService
  ) {
    this.beneficiaires = config.data;
  }

  ngOnInit() {}

  handleValider() {
    this.confirmationService.confirm({
      message:
        "Vous allez ajouter une liste de bénéficiaire, veuillez confirmer",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      key: "confirmSaveAllDialog",
      accept: () => {
        this.isSavingBeneficiaires = true;
        let listToSave: BeneficiaireTable[] = [];
        let organisation = JSON.parse(localStorage.getItem("organisation"));

        this.beneficiaires.forEach((element) => {
          listToSave.push(
            new BeneficiaireTable(
              organisation.organisationId,
              element.civilite,
              element.nom,
              element.prenoms,
              element.email.toString().trim().toLowerCase(),
              element.date_naissance
            )
          );
        });

        this.beneficiaireUseCase.execute(listToSave).subscribe(
          (response) => {
            this.isSavingBeneficiaires = false;
            if (response.status) {
              this.ref.close({
                type: "success",
                message: "Vous avez ajouté des bénéficiaires avec succès",
                summary: "Success",
              });
            }
          },
          (data) => {
            this.isSavingBeneficiaires = false;
            let erreurMessage: string;
            if (data.status == 401) {
              erreurMessage = "Votre session a expiré...";
            } else if (data.status == 409) {
              erreurMessage =
                "Un ou plusieurs bénéficiaires sont déjà rattachés à une autre entreprise";
            } else {
              erreurMessage = data.statusText;
            }

            this.ref.close({
              type: "error",
              message: erreurMessage,
              summary: "Error",
            });
          }
        );
      },
      reject: () => {},
    });
  }

  handleClose() {
    this.ref.close();
  }
}

<p-toast key="success_beneficiaire"></p-toast>
<p-confirmDialog [style]="{ width: '40vw' }" key="confirmSaveAllDialog"></p-confirmDialog>

<div>
  <form [formGroup]="formGroup" novalidate>
    <div class="grid-container">
      <div class="item">
        <div class="form-group">
          <label>Civilité</label>
          <select class="form-control" formControlName="civilite">
            <option value="Monsieur">Monsieur</option>
            <option value="Madame">Madame</option>
          </select>
        </div>
        <div *ngIf="formGroup.controls['civilite'].invalid && (formGroup.controls['civilite'].dirty || formGroup.controls['civilite'].touched)" class="form-alert alert-danger">
          <div *ngIf="formGroup.controls['civilite'].errors.required">
            La civilité est obligatoire.
          </div>
        </div>
      </div>
      <div class="item1">
        <div class="form-group">
          <label>Nom</label>
          <input class="form-control" formControlName="nom" type="text">
        </div>
        <div *ngIf="formGroup.controls['nom'].invalid && (formGroup.controls['nom'].dirty || formGroup.controls['nom'].touched)" class="form-alert alert-danger">
          <div *ngIf="formGroup.controls['nom'].errors.required">
            Le nom est obligatoire.
          </div>
        </div>
      </div>
      <div class="item2">
        <div class="form-group">
          <label>Prénoms</label>
          <input class="form-control" formControlName="prenoms" type="text">
        </div>
        <div *ngIf="formGroup.controls['prenoms'].invalid && (formGroup.controls['prenoms'].dirty || formGroup.controls['prenoms'].touched)" class="form-alert alert-danger">
          <div *ngIf="formGroup.controls['prenoms'].errors.required">
            Le(s) prénom(s) est obligatoire.
          </div>
        </div>
      </div>
<!--      <div class="item1">-->
<!--        <div class="form-group">-->
<!--          <label>Date de naissance</label>-->
<!--          <input class="form-control" formControlName="date_naissance" type="date">-->
<!--        </div>-->
<!--        <div *ngIf="formGroup.controls['date_naissance'].invalid && (formGroup.controls['date_naissance'].dirty || formGroup.controls['date_naissance'].touched)" class="form-alert alert-danger">-->
<!--          <div *ngIf="formGroup.controls['date_naissance'].errors.required">-->
<!--            La date de naissance est obligatoire.-->
<!--          </div>-->
<!--          <div *ngIf="formGroup.controls['date_naissance'].errors.pattern">-->
<!--            Le format de la date est incorrect.-->
<!--          </div>-->
<!--          <div *ngIf="formGroup.controls['date_naissance'].errors.mineurError">-->
<!--            Ce bénéficiaire est un mineur de moins de 15 ans.-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="item">
        <div class="form-group">
          <label>Email</label>
          <input class="form-control" formControlName="email" type="email">
        </div>
        <div *ngIf="formGroup.controls['email'].invalid && (formGroup.controls['email'].dirty || formGroup.controls['email'].touched)" class="form-alert alert-danger">
          <div *ngIf="formGroup.controls['email'].errors.required">
            L'email est obligatoire.
          </div>
          <div *ngIf="formGroup.controls['email'].errors.pattern">
            Le format de l'email est incorrect.
          </div>
          <div *ngIf="formGroup.controls['email'].errors.alreadyExist">
            Ce bénéficiaire existe déjà.
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; width: 100%; justify-content: center; padding-top: 50px">
      <button
        class="btn-annuler"
        type="button"
        pButton
        label="Valider et fermer"
        (click)="handleValider('AndClose')"
        [disabled]="isSavingBeneficiaires"
      ></button>

      <button
        style="margin-left: 15px"
        class="btn-valider"
        type="button"
        pButton
        label="Valider et ajouter un autre"
        (click)="handleValider('AndContinue')"
        [disabled]="isSavingBeneficiaires"
      ></button>
    </div>
  </form>
</div>

<div *ngIf="isSavingBeneficiaires"
     style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-top: 35px;
      "
>
  <mat-spinner [diameter]="50" mode="indeterminate" strokeWidth="5"></mat-spinner>
</div>

<div style="display: flex;justify-content: center; align-items: center;height: 100%;padding-top: 25px;">
  <div *ngIf="erreurServeur !== ''" class="my-loader">
    <span>{{ erreurServeur }}</span>
  </div>
</div>


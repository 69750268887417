import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor() {}

  downloadCsvModelAttribution(): void {
    let headerCsv: string[] = [
      "nom",
      "prenoms",
      "email",
      "nombre_ticket"
    ];

    let data = [
      {
        nom: "Doe",
        prenoms: "John",
        email: "john@doe.co",
        nombre_ticket: 20
      },
    ];
    let csv: string = this.convertToCsv(data, headerCsv);
    this.downloadCsvFile(csv, "file");
  }

  downloadCsvModelBeneficiaire(): void {
    let headerCsv: string[] = [
      "civilite",
      "nom",
      "prenoms",
      "email"
    ];

    let data = [
      {
        civilite: "Monsieur",
        nom: "Doe",
        prenoms: "John",
        email: "john@doe.com"
      },
    ];
    let csv: string = this.convertToCsv(data, headerCsv);
    this.downloadCsvFile(csv, "file");
  }

  private convertToCsv(objArray: any, headerList: string[]): string {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    console.log(array);

    let str = "";
    let row = "";
    for (const index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in headerList) {
        const head = headerList[index];
        line += array[i][head] + ",";
      }
      line = line.slice(0, -1);
      str += line + "\r\n";
    }
    return str;
  }

  private downloadCsvFile(csvData: any, filename: string): void {
    console.log(csvData);
    const blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    const dwldLink = document.createElement("a");
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  downloadFile(url: string) {
    console.log(url);
    window.open(url, "__blank");
  }
}

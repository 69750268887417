import { UserModel } from "../../data/model/user.model";
import { LoginUseCase } from "../../usecases/login-use-case";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { deconnexion } from "src/app/helpers/helpers";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  unAuthorized: boolean;
  isLoadingConnexion: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginUseCase: LoginUseCase
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  connexion() {
    this.isLoadingConnexion = true;
    this.unAuthorized = false;
    if (this.form.valid) {
      this.loginUseCase
        .execute(this.form.value["username"], this.form.value["password"])
        .subscribe(
          (data: UserModel) => {
            console.log(data);
            this.isLoadingConnexion = false;
            this.router.navigateByUrl("/dashboard");
          },
          (err: any) => {
            this.isLoadingConnexion = false;
            this.unAuthorized = true;
            console.log(err);
            console.log("email ou mot de passe incorrect...");
          }
        );
    } else {
      console.log(this.form.errors);
      this.isLoadingConnexion = false;
      this.unAuthorized = true;
    }
  }
}

import { UserModel } from "../model/user.model";

export class OrganisationEntity {
  organisationId: string;
  organisationNom: string;
  adresse: string;
  organisationNumber: string;
  organisationEmail: string;
  logo: string;
  taux: number;
  typeTaux: string;
  prixUnitaireTicket: number;

  constructor(
    organisationId: string,
    organisationNom: string,
    adresse: string,
    organisationNumber: string,
    organisationEmail: string,
    logo: string,
    taux: number,
    typeTaux: string,
    prixUnitaireTicket: number
  ) {
    this.organisationId = organisationId;
    this.organisationNom = organisationNom;
    this.adresse = adresse;
    this.organisationNumber = organisationNumber;
    this.organisationEmail = organisationEmail;
    this.logo = logo;
    this.taux = taux;
    this.typeTaux = typeTaux;
    this.prixUnitaireTicket = prixUnitaireTicket;
  }

  static toOrganisationEntity(param: UserModel): OrganisationEntity {
    return new OrganisationEntity(
      param.organisation.organisationId,
      param.organisation.organisationNom,
      param.organisation.adresse,
      param.organisation.organisationNumber,
      param.organisation.organisationEmail,
      param.organisation.logo,
      param.organisation.taux,
      param.organisation.typeTaux,
      param.organisation.prixUnitaireTicket
    );
  }
}

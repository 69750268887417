import { AttributionUserModel } from "./../../../data/model/attribution-user.model";
import { RoutingService } from "./../../../services/routing.service";
import { OrganisationEntity } from "./../../../data/entity/organisation.entity";
import { FileService } from "./../../../services/file.service";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { csvToJson } from "csvtojson-converter";
import {
  checkDuplicateValues,
  checkIfUsersInTheOrganisation,
  isIncorrectPrice,
  isNotMultipleOf,
  isSmallerThan,
} from "src/app/helpers/helpers";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { CommandeUseCase } from "../../../usecases/commande-use-case";
import { BeneficiaireRDS } from "../../../data/datasource/remote-datasource/beneficiaire-rds";
import { BeneficiaireModel } from "../../../data/model/beneficiaire.model";
import { StepModel } from "src/app/data/model/step.model";
import { CommandeModel } from "src/app/data/model/commande.model";
import { log } from "console";

@Component({
  selector: "app-new-attribution",
  templateUrl: "./new-attribution.component.html",
  styleUrls: ["./new-attribution.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class NewAttributionComponent implements OnInit {
  status: string;
  sommeTotale: number = 0;
  taux: number = 0;
  typeTaux: string;
  sommeGlobale: number = 0;
  attributionsUsers: AttributionUserModel[] = [];
  donneesCsv: AttributionUserModel[] = [];
  regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  clonedAttributions: { [s: string]: AttributionUserModel } = {};
  beneficiairesOrganisation: BeneficiaireModel[];
  organisationId: string;
  isLoading: boolean = false;
  elementIndex: number = 1;
  messages: Message[];
  prix_unitaire: number = 0;
  minDate: string;
  dateExecution: string;
  isValid: string;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private commandeUseCase: CommandeUseCase,
    private fileService: FileService,
    private routingService: RoutingService,
    private beneficiaireRDS: BeneficiaireRDS
  ) {}

  ngOnInit() {
    const organisation: OrganisationEntity = JSON.parse(
      localStorage.getItem("organisation")
    );
    this.organisationId = organisation.organisationId;
    this.taux = organisation.taux;
    this.typeTaux = organisation.typeTaux;
    this.prix_unitaire = organisation.prixUnitaireTicket;
    this.beneficiaireRDS
      .getOrganisationBeneficiaire(this.organisationId)
      .subscribe((result) => {
        console.log(result);
        this.beneficiairesOrganisation = result;
      });

    this.minDate = this.minDate = new Date(Date.now() + 3600 * 1000 * 24)
      .toISOString()
      .split("T")[0];
  }

  public getCommission(amount: number): number {
    return this.typeTaux == "percentage"
      ? amount * (this.taux / 100)
      : this.taux;
  }

  public getMontantPlusCommission(amount: number): number {
    return this.typeTaux == "percentage"
      ? amount + amount * (this.taux / 100)
      : amount + this.taux;
  }

  public onFileChange(event): void {
    this.attributionsUsers = [];
    this.sommeGlobale = 0;
    this.sommeTotale = 0;
    this.elementIndex = 1;
    this.status = "progress";

    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length > 0) {
      const reader: FileReader = new FileReader();
      reader.readAsText(target.files[0]);

      reader.onload = (e: any) => {
        const delimiter = ",";
        this.donneesCsv = csvToJson(reader.result, delimiter);

        if (this.donneesCsv.length !== 0) {
          if (
            Object.keys(this.donneesCsv[0]).includes("nom") &&
            Object.keys(this.donneesCsv[0]).includes("prenoms") &&
            Object.keys(this.donneesCsv[0]).includes("email") &&
            Object.keys(this.donneesCsv[0]).includes("nombre_ticket")
          ) {
            if (this.checkFile("donneesCsv") === "loaded") {
              console.log("after load csv...");

              console.log("csv loaded...");
              console.log(this.donneesCsv);

              console.log("this.prix_unitaire");
              console.log(this.prix_unitaire);

              this.attributionsUsers = [];

              this.donneesCsv.forEach((data, index) => {
                let attribution: AttributionUserModel = {
                  id: index,
                  nom: data.nom,
                  prenoms: data.prenoms,
                  email: data.email,
                  nombre_ticket: data.nombre_ticket,
                  prix_unitaire: data.prix_unitaire,
                };
                console.log("attribution" + index);
                console.log(attribution);

                this.attributionsUsers.push(attribution);
              });

              console.log("this.attributionsUsers");
              console.log(this.attributionsUsers);

              this.sommeTotale = this.attributionsUsers.reduce(
                (total, user) =>
                  total + user.nombre_ticket * this.prix_unitaire,
                0
              );
              this.sommeGlobale = this.sommeGlobale =
                this.getMontantPlusCommission(this.sommeTotale);
            }
          } else {
            this.status = "failed";
            this.messages = [
              {
                severity: "error",
                summary: "",
                detail: "Fichier non conforme...",
              },
            ];
          }
        } else {
          this.status = "empty";
          this.messages = [
            {
              severity: "error",
              summary: "",
              detail: "Aucun bénéficiaire dans le tableau...",
            },
          ];
        }
      };
    }
    event.target.value = null;
  }

  checkFile(typeCheck: string) {
    this.beneficiaireRDS
      .getOrganisationBeneficiaire(this.organisationId)
      .subscribe((result) => {
        this.beneficiairesOrganisation = result;
      });

    if (typeCheck === "donneesCsv") {
      for (const [index, element] of this.donneesCsv.entries()) {
        if (
          element.nom === null ||
          element.nom === undefined ||
          element.nom.trim() === ""
        ) {
          this.status = "error_nom";
          this.messages = [
            {
              severity: "error",
              summary: "",
              detail: "Vérifiez les noms des bénéficiaires...",
            },
          ];
          break;
        }

        if (
          element.prenoms === null ||
          element.prenoms === undefined ||
          element.prenoms.trim() === ""
        ) {
          this.status = "error_prenoms";
          this.messages = [
            {
              severity: "error",
              summary: "",
              detail: "Vérifiez les prénoms des bénéficiaires...",
            },
          ];
          break;
        }

        if (
          element.email === null ||
          element.email === undefined ||
          element.email.trim() === "" ||
          !this.regex.test(element.email)
        ) {
          this.status = "error_email";
          this.messages = [
            {
              severity: "error",
              summary: "",
              detail: "Erreur, vérifiez les emails des bénéficiaires...",
            },
          ];
          break;
        }

        if (
          element.nombre_ticket === null ||
          element.nombre_ticket === undefined ||
          !Number.isInteger(element.nombre_ticket) ||
          element.nombre_ticket <= 0
        ) {
          this.status = "error_nombre_ticket";
          this.messages = [
            {
              severity: "error",
              summary: "",
              detail:
                "Vérifiez les nombres de ticket attribués des bénéficiaires...",
            },
          ];
          break;
        }

        this.donneesCsv[index].id = this.elementIndex;
        this.elementIndex++;
      }

      if (checkDuplicateValues(this.donneesCsv)) {
        this.status = "error_doublon_email";
        this.messages = [
          {
            severity: "error",
            summary: "",
            detail: "Erreur doublon, vérifiez les emails des bénéficiaires...",
          },
        ];
      }

      if (
        checkIfUsersInTheOrganisation(
          this.beneficiairesOrganisation,
          this.donneesCsv
        ) === "user_not_found"
      ) {
        this.status = "user_not_found";
        this.messages = [
          {
            severity: "error",
            summary: "",
            detail:
              "Un bénéficiaire non répertorié dans l'organisation a été détecté...",
          },
        ];
      }

      this.status = this.status === "progress" ? "loaded" : this.status;

      return this.status;
    } else {
      for (const element of this.attributionsUsers) {
        if (
          element.nom === null ||
          element.nom === undefined ||
          element.nom.trim() === ""
        ) {
          this.status = "error_nom";
          this.messages = [
            {
              severity: "error",
              summary: "",
              detail: "Vérifiez les noms des bénéficiaires...",
            },
          ];
          break;
        }

        if (
          element.prenoms === null ||
          element.prenoms === undefined ||
          element.prenoms.trim() === ""
        ) {
          this.status = "error_prenoms";
          this.messages = [
            {
              severity: "error",
              summary: "",
              detail: "Vérifiez les prénoms des bénéficiaires...",
            },
          ];
          break;
        }

        if (
          element.email === null ||
          element.email === undefined ||
          element.email.trim() === "" ||
          !this.regex.test(element.email)
        ) {
          this.status = "error_email";
          this.messages = [
            {
              severity: "error",
              summary: "",
              detail: "Erreur, vérifiez les emails des bénéficiaires...",
            },
          ];
          break;
        }

        if (
          element.nombre_ticket === null ||
          element.nombre_ticket === undefined ||
          !Number.isInteger(element.nombre_ticket) ||
          element.nombre_ticket <= 0
        ) {
          this.status = "error_nombre_ticket";
          this.messages = [
            {
              severity: "error",
              summary: "",
              detail:
                "Vérifiez les nombres de ticket attribués des bénéficiaires...",
            },
          ];
          break;
        }
      }

      if (checkDuplicateValues(this.attributionsUsers)) {
        this.status = "error_doublon_email";
        this.messages = [
          {
            severity: "error",
            summary: "",
            detail: "Erreur doublon, vérifiez les emails des bénéficiaires...",
          },
        ];
      }

      if (
        checkIfUsersInTheOrganisation(
          this.beneficiairesOrganisation,
          this.attributionsUsers
        ) === "user_not_found"
      ) {
        this.status = "user_not_found";
        this.messages = [
          {
            severity: "error",
            summary: "",
            detail:
              "Un bénéficiaire non répertorié dans l'organisation est a été détecté...",
          },
        ];
      }

      this.status = this.status === "progress" ? "loaded" : this.status;

      return this.status;
    }
  }

  addBeneficiaire() {
    const newAttribution = {
      id: this.elementIndex,
      nom: "",
      prenoms: "",
      email: "",
      nombre_ticket: 0,
      prix_unitaire: this.prix_unitaire,
    };
    this.attributionsUsers.push(newAttribution);
    this.elementIndex++;
  }

  deleteItem(attribution: AttributionUserModel) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + attribution.email + "?",
      header: "Confirm",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.attributionsUsers = this.attributionsUsers.filter(
          (val) => val.id !== attribution.id
        );
        this.onSommeChange();
      },
    });
  }

  onRowEditInit(attribution: AttributionUserModel) {
    this.clonedAttributions[attribution.id] = { ...attribution };
  }

  onRowEditSave(attribution: AttributionUserModel) {
    delete this.clonedAttributions[attribution.id];
  }

  onRowEditCancel(attribution: AttributionUserModel, index: number) {
    this.attributionsUsers[index] = this.clonedAttributions[attribution.id];
    delete this.clonedAttributions[attribution.id];
    this.onSommeChange();
  }

  onEndTabAddRow() {
    this.addBeneficiaire();
  }

  onSommeChange(prix_unitaire?: number) {
    this.sommeTotale = this.attributionsUsers.reduce(
      (total, user) => total + user.nombre_ticket * this.prix_unitaire,
      0
    );
    this.sommeGlobale = this.getMontantPlusCommission(this.sommeTotale);
  }

  runCommande() {
    console.log("prix_unitaire");
    console.log(this.prix_unitaire);

    this.confirmationService.confirm({
      message: "Voulez-vous vraiment valider la commande ?",
      header: "Confirm",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.createCommande(this.attributionsUsers, this.prix_unitaire);
      },
    });
  }
  createCommande(users: AttributionUserModel[], price: number) {
    // console.log("attributionsUsers...");
    // console.log(users);

    // console.log("prix_unitaire");
    // console.log(price);

    // console.log("create commande...");
    // this.isValid = "";
    // this.isLoading = true;
    // console.log(this.dateExecution);
    if (
      isIncorrectPrice(price) ||
      isSmallerThan(price, 1500) ||
      isNotMultipleOf(price, 100)
    ) {
      this.isLoading = false;
      this.isValid = "not-valid";
      this.status = "not_valid";
      this.messages = [
        {
          severity: "error",
          summary: "",
          detail: "Veuiller vérifier la valeur unitaire du ticket...",
        },
      ];
      return;
    }

    if (this.dateExecution == "" || this.dateExecution == undefined) {
      console.log("premier test...");
      this.isLoading = false;
      this.isValid = "not-valid";
      this.status = "not_valid";
      this.messages = [
        {
          severity: "error",
          summary: "",
          detail: "Veuiller remplir la date d'execution...",
        },
      ];
      return;
    }

    let newAttribution: AttributionUserModel[] = [];
    users.forEach((data, index) => {
      let attribution: AttributionUserModel = {
        id: index,
        nom: data.nom,
        prenoms: data.prenoms,
        email: data.email,
        nombre_ticket: data.nombre_ticket,
        prix_unitaire: price,
      };
      console.log("attribution" + index);
      console.log(attribution);

      newAttribution.push(attribution);
    });

    // console.log("attributionsUsers before send...");
    // console.log(newAttribution);

    // console.log("prix_unitaire before send...");
    // console.log(price);

    let sommeTotale = newAttribution.reduce(
      (total, user) => total + user.nombre_ticket * user.prix_unitaire,
      0
    );

    // console.log("sommeTotale before send...");
    // console.log(sommeTotale);

    let sommeGlobale = this.getMontantPlusCommission(sommeTotale);

    // console.log("sommeGlobale before send...");
    // console.log(sommeGlobale);

    if (this.validateAllFormFields() === "loaded") {
      this.commandeUseCase
        .execute(
          newAttribution,
          sommeTotale,
          this.getCommission(sommeTotale),
          sommeGlobale,
          this.taux,
          this.dateExecution
        )
        .subscribe(
          (result) => {
            if (result) {
              this.isLoading = false;
              this.confirmationService.confirm({
                message: "Commande créée avec succès",
                header: "Confirmation",
                icon: "pi pi-check-circle",
                rejectVisible: false,
                acceptLabel: "Ok",
                accept: () => {
                  this.routingService.redirect("attribution");
                },
              });
            } else {
              this.isLoading = false;
              this.messageService.add({
                key: "error_commande",
                severity: "error",
                summary: "Erreur",
                detail: "Erreur réessayer !",
                life: 3000,
              });
            }
          },
          (error) => {
            this.isLoading = false;
            this.messageService.add({
              key: "error_commande",
              severity: "error",
              summary: "Erreur",
              detail: "Erreur réessayer !",
              life: 3000,
            });
          }
        );
    } else {
      this.isLoading = false;
    }
  }

  validateAllFormFields() {
    console.log("second test");

    this.status = "progress";

    if (!this.attributionsUsers.length) {
      this.status = "empty";
      this.messages = [
        {
          severity: "error",
          summary: "",
          detail: "Veuiller ajouter des bénéficiaires...",
        },
      ];
      return this.status;
    } else {
      return this.checkFile("attributionsUsers");
    }
  }

  downloadCsv() {
    this.fileService.downloadCsvModelAttribution();
  }
}

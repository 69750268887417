import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {BeneficiaireRepository} from '../data/repository/beneficiaire.repository';
import {BeneficiaireTable} from '../data/model/beneficiaireTable.model';

@Injectable({
  providedIn: "root",
})
export class BeneficiaireUseCase {
  constructor(private beneficiaireRepository: BeneficiaireRepository) {}

  execute(beneficiaires: BeneficiaireTable[]): Observable<any> {
    return this.beneficiaireRepository.createAll(beneficiaires);
  }
}

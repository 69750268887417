<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-table
  [value]="attributionsUsers"
  dataKey="id"
  #dt1
  [rows]="5"
  [showCurrentPageReport]="true"
  [paginator]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [globalFilterFields]="['nom', 'prenoms', 'email']"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-left ml-auto" style="margin-right: 10px">
        <input
          pInputText
          type="text"
          (input)="dt1.filterGlobal($event.target.value, 'contains')"
          placeholder="Rechercher"
        />
      </span>
      <span class="p-input-icon-left">
        <i style="color: white" class="pi pi-plus"></i>
        <input
          (click)="addBeneficiaire()"
          style="padding-left: 30px"
          class="h-button"
          type="button"
          value="Ajouter"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Nom</th>
      <th>Prenom</th>
      <th>Email</th>
      <th>Nombre Tickets</th>
      <th>Montant</th>
      <th style="width: 5rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-beneficiaire>
    <tr>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [(ngModel)]="beneficiaire.nom" />
          </ng-template>
          <ng-template pTemplate="output">
            {{ beneficiaire.nom }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn class="ellipsis">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              type="text"
              [(ngModel)]="beneficiaire.prenoms"
              required
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ beneficiaire.prenoms }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn class="ellipsis">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              type="text"
              [(ngModel)]="beneficiaire.email"
              required
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ beneficiaire.email }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              type="text"
              [(ngModel)]="beneficiaire.nombre_ticket"
              (ngModelChange)="onSommeChange()"
              required
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ beneficiaire.nombre_ticket }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              type="text"
              [(ngModel)]="beneficiaire.prix_unitaire"
              (ngModelChange)="onSommeChange()"
              required
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ beneficiaire.prix_unitaire }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <button
          pButton
          icon="pi pi-trash"
          class="p-button-rounded"
          (click)="deleteItem(beneficiaire)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="4" class="text-right">Total</td>
      <td colspan="2">{{ sommeTotale }}</td>
    </tr>
    <tr>
      <td colspan="4" class="text-right">Total</td>
      <td colspan="2">{{ sommeTotale }}</td>
    </tr>
  </ng-template>
</p-table>
<div>
  <button
    type="button"
    pButton
    icon="pi pi-info-circle"
    label="Valider"
  ></button>
</div>

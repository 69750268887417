import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { getJwt, deconnexion } from "../helpers/helpers";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const token = getJwt();
    console.log("check token guard...");
    console.log(token);

    if (token != null || token != undefined) {
      return true;
    } else {
      deconnexion();
      this.router.navigateByUrl("/");
      return false;
    }
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { conf } from "src/app/config/config";
import { getHeadersWithAccessToken } from "src/app/helpers/helpers";
import { AideModel } from "../../model/aide.model";

@Injectable({
  providedIn: "root",
})
export class HelpRDS {
  constructor(private http: HttpClient) {}

  sendData(help: AideModel): Observable<AideModel> {
    return this.http.post<AideModel>(
      conf.ipAddr + "/samafils/v1/samafils/help",
      {
        email: help.email,
        organisation: help.organisation,
        sujet: help.sujet,
        description: help.description,
      },
      { headers: getHeadersWithAccessToken() }
    );
  }
}

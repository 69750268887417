import { ProfessionelService } from "./../../../services/professionel.service";
import { OrganisationEntity } from "./../../../data/entity/organisation.entity";
import { OrganisationService } from "./../../../services/organisation.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  fullName: string;

  constructor(
    private router: Router,
    private organisationService: OrganisationService,
    private professionelService: ProfessionelService
  ) {}

  ngOnInit() {
    if (this.organisationService.getOrganisation().value == null) {
      this.organisationService.getLocalStorageOrganisation();
    }

    if (this.professionelService.getProfessionnel().value == null) {
      this.professionelService.getLocalStorageProfessionnel();
    }

    this.organisationService
      .getOrganisation()
      .subscribe((data: OrganisationEntity) => {
        this.fullName = data.organisationNom;
      });
  }
}

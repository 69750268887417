import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { deconnexion } from "src/app/helpers/helpers";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"],
})
export class NavigationComponent implements OnInit {
  constructor(private router: Router) {}

  @Input() selectedItem: number;

  ngOnInit() {}

  public redirect(url: string) {
    this.router.navigateByUrl(url);
  }

  public logout() {
    deconnexion();
    this.router.navigateByUrl("/");
  }
}

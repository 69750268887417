import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {BeneficiaireRDS} from '../datasource/remote-datasource/beneficiaire-rds';
import {BeneficiaireTable} from '../model/beneficiaireTable.model';

@Injectable({
  providedIn: "root",
})
export class BeneficiaireRepository {
  constructor(private beneficiaireRDS: BeneficiaireRDS) {}

  createAll(beneficiaires: BeneficiaireTable[]): Observable<any> {
    return this.beneficiaireRDS.createAll(beneficiaires);
  }
}

<div class="connexion">
  <div class="left-block">
    <div class="left-box">
      <div class="text-title">
        <h1>Bienvenue dans votre espace Hatwork Pro !</h1>
      </div>
      <div class="text-description">
        Souriez, vous avez entre les mains le meilleur outil pour motiver vos
        salariés
      </div>
    </div>
    <div class="left-box child2"></div>
  </div>
  <div class="right-block">
    <div class="connexion-box">
      <div class="logo">
        <img
          src="../../../assets/img/HATWORK LOGO NOUVEAU_Hatwork Logo.png"
          alt="logo-hw"
        />
      </div>
      <div *ngIf="unAuthorized">
        <div class="alert">Login ou Mot de passe incorrect...</div>
      </div>
      <form [formGroup]="form">
        <div class="title">
          <h2>Se connecter</h2>
        </div>
        <div class="input text">
          <label>Email</label>
          <input type="email" formControlName="username" />
        </div>
        <div class="input password">
          <label>Mot de passe</label>
          <input type="password" formControlName="password" />
        </div>
        <div class="button">
          <div *ngIf="!isLoadingConnexion">
            <button type="button" (click)="connexion()" class="cancelbtn">
              Connexion
            </button>
          </div>
          <div class="loading-container" *ngIf="isLoadingConnexion">
            <mat-spinner
              mode="indeterminate"
              strokeWidth="5"
              [diameter]="50"
            ></mat-spinner>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { TokenResponseService } from "../services/token-response.service";
import { TokenResponseEntity } from "../data/entity/token-response.entity";
import { OrganisationService } from "../services/organisation.service";
import { ProfessionelEntity } from "../data/entity/professionnel.entity";
import { map, tap } from "rxjs/operators";
import { LoginRepository } from "../data/repository/login.repository";
import { UserModel } from "../data/model/user.model";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProfessionelService } from "../services/professionel.service";
import { OrganisationEntity } from "../data/entity/organisation.entity";
import { createCookie, deleteAllCookies } from "../helpers/helpers";

@Injectable({
  providedIn: "root",
})
export class LoginUseCase {
  constructor(
    private loginRepository: LoginRepository,
    private professionelService: ProfessionelService,
    private organisationService: OrganisationService,
    private tokenResponseService: TokenResponseService
  ) {}

  execute(email: string, password: string): Observable<UserModel> {
    return this.loginRepository.login(email, password).pipe(
      tap((userModel: any) => {
        console.log("userModel");
        console.log(userModel);

        const prof = ProfessionelEntity.toProfessionelEntity(userModel);
        const org = OrganisationEntity.toOrganisationEntity(userModel);
        const access = TokenResponseEntity.toTokenResponseEntity(userModel);

        console.log("create cookie...");
        console.log(access.access_token);
        console.log(access.expires_in);
        console.log(org);

        createCookie(access.access_token, access.expires_in);

        localStorage.setItem("professionnel", JSON.stringify(prof));
        localStorage.setItem("organisation", JSON.stringify(org));

        this.professionelService.setProfessionnel(prof);
        this.organisationService.setOrganisation(org);
      })
    );
  }
}

import { HelpRepository } from "./../data/repository/help-repository";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { AideModel } from "../data/model/aide.model";

@Injectable({
  providedIn: "root",
})
export class HelpUseCase {
  constructor(private helpRepository: HelpRepository) {}

  execute(help: AideModel): Observable<AideModel> {
    return this.helpRepository.sendData(help);
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { OrganisationEntity } from "../data/entity/organisation.entity";

@Injectable({
  providedIn: "root",
})
export class OrganisationService {
  private organisation: BehaviorSubject<OrganisationEntity> =
    new BehaviorSubject<OrganisationEntity>(null);

  constructor() {}

  setOrganisation(org: OrganisationEntity): void {
    this.organisation.next(org);
  }

  getOrganisation(): BehaviorSubject<OrganisationEntity> {
    return this.organisation;
  }

  getLocalStorageOrganisation() {
    const organisation = JSON.parse(localStorage.getItem("organisation"));
    this.organisation.next(organisation);
  }
}

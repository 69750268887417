export class AideModel {
  public email: string;
  public organisation: string;
  public sujet: string;
  public description: string;

  constructor(
    email: string,
    organisation: string,
    sujet: string,
    description: string
  ) {
    this.email = email;
    this.organisation = organisation;
    this.sujet = sujet;
    this.description = description;
  }
}

import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommandeModel } from "../../model/commande.model";
import { conf } from "src/app/config/config";
import { getHeadersWithAccessToken } from "src/app/helpers/helpers";

@Injectable({
  providedIn: "root",
})
export class CommandeRDS {
  constructor(private http: HttpClient) {}

  create(commande: CommandeModel): Observable<CommandeModel> {
    return this.http.post<CommandeModel>(
      conf.ipAddr + "/commande/v1/commandes/",
      commande,
      { headers: getHeadersWithAccessToken() }
    );
  }

  list(id: string): Observable<CommandeModel[]> {
    return this.http.get<CommandeModel[]>(
      conf.ipAddr + "/commande/v1/commandes/organisation/" + id,
      { headers: getHeadersWithAccessToken() }
    );
  }

  latest(id: string): Observable<CommandeModel> {
    return this.http.get<CommandeModel>(
      conf.ipAddr + "/commande/v1/commandes/latest/" + id,
      { headers: getHeadersWithAccessToken() }
    );
  }
}

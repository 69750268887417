import { LoginRDS } from '../datasource/remote-datasource/login.rds';
import { Injectable } from "@angular/core";
import { UserModel } from "../model/user.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoginRepository {
  constructor(private loginRds: LoginRDS) {}

  login(email: string, password: string): Observable<UserModel> {
    return this.loginRds.login(email, password);
  }
}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { AttributionComponent } from "./views/attribution/attribution.component";
import { HeaderComponent } from "./views/components/header/header.component";
import { NavigationComponent } from "./views/components/navigation/navigation.component";
import { LoginComponent } from "./views/login/login.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BeneficiaireComponent } from "./views/beneficiaire/beneficiaire.component";
import { BeneficiaireStatusComponent } from "./views/beneficiaire-status/beneficiaire-status.component";
import { PreviewAttributionComponent } from "./views/attribution/preview-attributions/preview-attribution.component";
import { FormatNumberPipe } from "./pipe/format-number.pipe";
import { AideComponent } from "./views/aide/aide.component";
import { NewAttributionComponent } from "./views/attribution/new-attribution/new-attribution.component";
import {
  LoadedBeneficiairePopupComponent
} from './views/beneficiaire/components/loaded-beneficiaire-popup/loaded-beneficiaire-popup.component';
import {
  OneBeneficiairePopupComponent
} from './views/beneficiaire/components/one-beneficiaire-popup/one-beneficiaire-popup.component';
import { ButtonModule } from "primeng/button";
import { DynamicDialogModule, DialogService } from "primeng/dynamicdialog";
import { DialogModule } from "primeng/dialog";
import { ToastModule } from "primeng/toast";
import { TableModule } from "primeng/table";
import { ConfirmationService, MessageService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RippleModule } from "primeng/ripple";
import { MessagesModule } from "primeng/messages";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AttributionComponent,
    LoginComponent,
    HeaderComponent,
    NavigationComponent,
    BeneficiaireComponent,
    BeneficiaireStatusComponent,
    FormatNumberPipe,
    AideComponent,
    PreviewAttributionComponent,
    NewAttributionComponent,
    LoadedBeneficiairePopupComponent,
    OneBeneficiairePopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    DynamicDialogModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    BrowserAnimationsModule,
    TableModule,
    FormsModule,
    MatProgressSpinnerModule,
    ConfirmDialogModule,
    RippleModule,
    MessagesModule,
  ],
  providers: [DialogService, ConfirmationService, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}

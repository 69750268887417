import { UserModel } from "../model/user.model";

export class TokenResponseEntity {
  public access_token: string;
  public refresh_token: string;
  public token_type: string;
  public expires_in: number;
  public scope: string;
  public jti: string;

  constructor(
    access_token: string,
    refresh_token: string,
    token_type: string,
    expires_in: number,
    scope: string,
    jti: string
  ) {
    this.access_token = access_token;
    this.refresh_token = refresh_token;
    this.token_type = token_type;
    this.expires_in = expires_in;
    this.scope = scope;
    this.jti = jti;
  }

  static toTokenResponseEntity(param: UserModel): TokenResponseEntity {
    return {
      access_token: param.tokenResponse.access_token,
      refresh_token: param.tokenResponse.refresh_token,
      token_type: param.tokenResponse.token_type,
      expires_in: param.tokenResponse.expires_in,
      scope: param.tokenResponse.scope,
      jti: param.tokenResponse.jti,
    };
  }
}

<app-header></app-header>
<main>
  <app-navigation [selectedItem]="1"></app-navigation>
  <section>
    <div class="title for-dashboard"><h1>Hatwork Account Manager</h1></div>
    <div
      *ngIf="isLoadingDashboard"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      "
    >
      <mat-spinner
        mode="indeterminate"
        strokeWidth="5"
        [diameter]="50"
      ></mat-spinner>
    </div>
    <div *ngIf="!isLoadingDashboard" class="body">
      <div class="first-section" *ngIf="latestCommande == null">
        <div class="img">
          <img
            class="icon-img"
            src="./../../../assets/img/plane-tickets.png"
            alt="ticket"
          />
        </div>
        <div class="third-text">Passer votre première commande.</div>
        <button type="button" (click)="redirect('attributions/add')">
          <span class="icon-text">Nouvelle Commande</span>
        </button>
      </div>
      <div
        class="first-section"
        *ngIf="
          latestCommande !== null && latestCommande.factureStatus == 'pending'
        "
      >
        <div class="img">
          <img
            class="icon-img"
            src="./../../../assets/img/file.png"
            alt="ticket"
          />
        </div>
        <div class="third-text">
          Vous avez une commande en attente de paiement.
        </div>
        <button type="button" (click)="redirect('attribution')">
          <span class="icon-text">Voir la commande</span>
        </button>
      </div>
      <div
        class="first-section"
        *ngIf="
          latestCommande !== null && latestCommande.factureStatus == 'activate'
        "
      >
        <div class="img">
          <img
            class="icon-img"
            src="./../../../assets/img/plane-tickets.png"
            alt="ticket"
          />
        </div>
        <div class="third-text">
          Votre dernière commande a été éffectuée
          {{ getRelativeDate(latestCommande.date) }}
        </div>
        <button type="button" (click)="redirect('attributions/add')">
          <span class="icon-text">Nouvelle Commande</span>
        </button>
      </div>
      <div class="second-section">
        <div class="container" (click)="redirect('attributions/add')">
          <div>
            <img
              class="icon"
              src="./../../../assets/img/plane-tickets.png"
              alt="ticket"
            />
          </div>
          <div class="icon-text">Nouvelle commande</div>
          <div class="second-text">Passez votre commande du mois.</div>
        </div>
        <p-toast></p-toast>
        <div class="container" (click)="redirect('beneficiaire?page=add')">
          <div>
            <img
              class="icon"
              src="./../../../assets/img/add-user.png"
              alt="ticket"
            />
          </div>
          <div class="icon-text">Ajouter un bénéficiaire</div>
          <div class="second-text">
            Rajoutez les nouveaux salariés dans la liste de vos bénéficiaires.
          </div>
        </div>
        <div
          class="container"
          *ngIf="
            latestCommande !== null &&
            latestCommande.factureStatus == 'activate'
          "
          (click)="downloadFile(latestCommande.factureUrl)"
        >
          <div>
            <img
              class="icon"
              src="./../../../assets/img/invoice.png"
              alt="ticket"
            />
          </div>
          <div class="icon-text">Dernière facture</div>
          <div class="second-text">Téléchargez votre dernière facture.</div>
        </div>
        <div
          class="container"
          *ngIf="
            latestCommande !== null &&
            latestCommande.factureStatus !== 'activate'
          "
          (click)="downloadFile(latestCommande.devisUrl)"
        >
          <div>
            <img
              class="icon"
              src="./../../../assets/img/invoice.png"
              alt="ticket"
            />
          </div>
          <div class="icon-text">Dernier devis</div>
          <div class="second-text">Téléchargez votre dernier devis.</div>
        </div>
      </div>
    </div>
  </section>
</main>

import { FileService } from "./../../services/file.service";
import { CommandeModel } from "src/app/data/model/commande.model";
import { CommandeUseCase } from "./../../usecases/commande-use-case";
import { RoutingService } from "./../../services/routing.service";
import { ProfessionelService } from "./../../services/professionel.service";
import { DashboardUseCase } from "./../../usecases/dashboard-use-case";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BeneficiaireService } from "src/app/services/beneficiaire.service";
import * as moment from "moment";
import { MessageService } from "primeng/api";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  providers: [MessageService],
})
export class DashboardComponent implements OnInit {
  public countPendingAttribution = 0;
  public countProgressAttribution = 0;
  public countActiveBeneficiaire = 0;
  public countAllBeneficiaire = 0;
  public isLoadingDashboard: boolean = false;
  public latestCommande: CommandeModel;

  constructor(
    private router: Router,
    private beneficiaireService: BeneficiaireService,
    private dashboardUseCase: DashboardUseCase,
    private professionelService: ProfessionelService,
    private commandeUseCase: CommandeUseCase,
    private routingService: RoutingService,
    private fileService: FileService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.isLoadingDashboard = true;

    if (this.professionelService.getProfessionnel().value == null) {
      this.professionelService.getLocalStorageProfessionnel();
    }

    this.commandeUseCase
      .latest(this.professionelService.getProfessionnel().value.organisationId)
      .subscribe(
        (_data: CommandeModel) => {
          console.log("dashboard CommandeModel");
          this.isLoadingDashboard = false;
          this.latestCommande = _data;

          console.log("dashboard latestCommande");
          console.log(this.latestCommande);
        },
        (err: any) => {
          this.isLoadingDashboard = false;
          // console.log(err);
        }
      );
  }

  public redirect(url: string) {
    this.routingService.redirect(url);
  }

  public downloadFile(url: string) {
    this.fileService.downloadFile(url);
  }

  public getRelativeDate(date: any) {
    const mDate = moment(date).locale("fr");
    return mDate.startOf(date).fromNow();
  }

  showToast() {
    this.messageService.add({
      severity: "info",
      summary: "Fonctionnalité",
      detail: "Fonctionnalité pas encore disponible",
    });
  }
}

import { conf } from '../../../config/config';
import { BeneficiaireModel } from '../../model/beneficiaire.model';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  getHeadersWithAccessToken,
} from "src/app/helpers/helpers";
import {BeneficiaireTable} from '../../model/beneficiaireTable.model';

@Injectable({
  providedIn: "root",
})
export class BeneficiaireRDS {
  constructor(private http: HttpClient) {}

  createAll(beneficiaires: BeneficiaireTable[]): Observable<any> {
    return this.http.post(
      conf.ipAddr + "/samafils/v1/samafils/beneficiaire/add",
      beneficiaires,
      { headers: getHeadersWithAccessToken(), observe: "response"}
    );
  }

  getOrganisationBeneficiaire(organisationId: string): Observable<BeneficiaireModel[]> {
    return this.http.get<BeneficiaireModel[]>(
      conf.ipAddr +
        "/samafils/v1/samafils/beneficiaire/list?id=" +
        organisationId,
      { headers: getHeadersWithAccessToken() }
    );
  }
}

import { Injectable } from "@angular/core";
import { CommandeRepository } from "../data/repository/commande.repository";
import { Observable } from "rxjs";
import { AttributionUserModel } from "../data/model/attribution-user.model";
import { CommandeModel } from "../data/model/commande.model";
import { StepModel } from "../data/model/step.model";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CommandeUseCase {
  constructor(private commandeRepository: CommandeRepository) {}

  list(id: string): Observable<CommandeModel[]> {
    return this.commandeRepository.list(id).pipe(
      tap((commandeModels: CommandeModel[]) => {
        console.log("commandeModels");
        console.log(commandeModels);
      })
    );
  }

  latest(id: string): Observable<CommandeModel> {
    return this.commandeRepository.latest(id).pipe(
      tap((commandeModel: CommandeModel) => {
        console.log("commandeModel");
        console.log(commandeModel);
      })
    );
  }

  execute(
    attributions: AttributionUserModel[],
    montant: number,
    commission,
    montantTotal,
    tauxCommission: number,
    dateExecution: string
  ): Observable<CommandeModel> {
    const organisation = JSON.parse(localStorage.getItem("organisation"));
    const professionnel = JSON.parse(localStorage.getItem("professionnel"));

    const totalTickets = attributions.reduce(
      (total, user) => total + user.nombre_ticket,
      0
    );
    const commandeToSave = new CommandeModel(
      "",
      new Date(),
      new Date(dateExecution),
      montant,
      commission,
      tauxCommission,
      montantTotal,
      organisation.organisationId,
      organisation.organisationNom,
      organisation.organisationEmail,
      professionnel.email,
      "",
      "",
      totalTickets,
      "",
      "",
      "",
      [
        new StepModel("validation devis", true),
        new StepModel("paiement", false),
        new StepModel("debut attribution", false),
        new StepModel("fin attribution", false),
      ],
      attributions
    );
    console.log(commandeToSave);
    return this.commandeRepository.create(commandeToSave);
  }
}

import { CommandeUseCase } from "./../../usecases/commande-use-case";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { CommandeModel } from "src/app/data/model/commande.model";
import { Router } from "@angular/router";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { PreviewAttributionComponent } from "./preview-attributions/preview-attribution.component";

@Component({
  selector: "app-attribution",
  templateUrl: "./attribution.component.html",
  styleUrls: ["./attribution.component.css"],
})
export class AttributionComponent implements OnInit {
  attributions: CommandeModel[];
  selectedItem: string;
  headerCsv: string[] = ["date", "numero", "montant", "status"];
  ref: DynamicDialogRef;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    public dialogService: DialogService,
    private commandeUseCase: CommandeUseCase
  ) {}

  ngOnInit() {
    this.isLoading = true;
    const organisation = JSON.parse(localStorage.getItem("organisation"));
    this.commandeUseCase.list(organisation.organisationId).subscribe(
      (_data: CommandeModel[]) => {
        this.attributions = _data;
        this.isLoading = false;
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  slide(id: string): void {
    this.selectedItem = this.selectedItem == id ? undefined : id;
  }

  downloadFile(url: string) {
    console.log(url);
    window.open(url, "__blank");
  }

  createNew() {
    this.router.navigateByUrl("/attributions/add");
  }

  createFromOld() {
    //TODO: faire appel vers le service pour recuperer la derniere commande
    // et le passer au component
    this.ref = this.dialogService.open(PreviewAttributionComponent, {
      header: "Bénéficiaires",
      width: "85%",
      contentStyle: { "min-height": "500px", overflow: "auto" },
      baseZIndex: 10000,
    });
  }
}

import { TokenResponseEntity } from "../data/entity/token-response.entity";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TokenResponseService {
  private tokenResponse: BehaviorSubject<TokenResponseEntity> =
    new BehaviorSubject<TokenResponseEntity>(null);

  constructor() {}

  // setProfessionnel(access: TokenResponseEntity): void {
  //   this.tokenResponse.next(access);
  // }
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommandeRDS } from "../datasource/remote-datasource/commande.rds";
import { CommandeModel } from "../model/commande.model";

@Injectable({
  providedIn: "root",
})
export class CommandeRepository {
  constructor(private commandeRDS: CommandeRDS) {}

  create(commande: CommandeModel): Observable<CommandeModel> {
    return this.commandeRDS.create(commande);
  }

  list(id: string): Observable<CommandeModel[]> {
    return this.commandeRDS.list(id);
  }

  latest(id: string): Observable<CommandeModel> {
    return this.commandeRDS.latest(id);
  }
}

import { Routes } from "@angular/router";
import { BeneficiaireStatusComponent } from "../views/beneficiaire-status/beneficiaire-status.component";
import { BeneficiaireComponent } from "../views/beneficiaire/beneficiaire.component";
import { DashboardComponent } from "../views/dashboard/dashboard.component";
import { LoginComponent } from "../views/login/login.component";
import { AideComponent } from "../views/aide/aide.component";
import { AuthGuard } from "../guard/auth-guard";
import {AttributionComponent} from '../views/attribution/attribution.component';
import {NewAttributionComponent} from '../views/attribution/new-attribution/new-attribution.component';

export const routes: Routes = [
  { path: "", component: LoginComponent },

  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  // { path: "attribution", component: AttributionComponent },
  {
    path: "beneficiaire",
    component: BeneficiaireComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "beneficiaire/:status",
    component: BeneficiaireStatusComponent,
    canActivate: [AuthGuard],
  },
  // { path: "attribution/:status", component: AttributionStatusComponent },
  // { path: "payment/history", component: PaymentHistoryComponent },
  { path: "aide", component: AideComponent, canActivate: [AuthGuard] },
  { path: "attribution", component: AttributionComponent, canActivate: [AuthGuard] },
  { path: "attributions/add", component: NewAttributionComponent },
];

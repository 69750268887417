import { BeneficiaireModel } from "../model/beneficiaire.model";
export class BeneficiaireEntity {
  public id: string;
  public email: string;
  public status: string;
  public nom: string;
  public prenom: string;
  public telephone: string;
  public dateNaissance: Date;

  constructor(
    id: string,
    email: string,
    status: string,
    nom: string,
    prenom: string,
    telephone: string,
    dateNaissance: Date
  ) {
    this.id = id;
    this.email = email;
    this.status = status;
    this.nom = nom;
    this.prenom = prenom;
    this.telephone = telephone;
    this.dateNaissance = dateNaissance;
  }

  static toBeneficiaireEntity(param: BeneficiaireModel): BeneficiaireEntity {
    return {
      id: param.id,
      email: param.email,
      status: param.status,
      nom: param.nom,
      prenom: param.prenom,
      telephone: param.telephone,
      dateNaissance: param.dateNaissance,
    };
  }
}

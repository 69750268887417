<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '40vw' }"></p-confirmDialog>

<app-header></app-header>
<main>
  <app-navigation [selectedItem]="5"></app-navigation>
  <section class="section">
    <div class="title"><h1>Hatwork Account Manager</h1></div>

    <div
      *ngIf="isLoadingBeneficiaires"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
      "
    >
      <mat-spinner
        mode="indeterminate"
        strokeWidth="5"
        [diameter]="50"
      ></mat-spinner>
    </div>

    <!-- <div *ngIf="isLoadingBeneficiaires" style="height: 100%"></div> -->

    <div
      *ngIf="!isLoadingBeneficiaires"
      class="other-body"
      style="flex-direction: column; justify-content: flex-start"
    >
      <!--Header-->
      <div style="display: flex; width: 100%; justify-content: space-between">
        <div>
          <div class="flex">
            <span class="p-input-icon-right">
              <i class="pi pi-search" style="margin-top: -12px"></i>
              <input
                pInputText
                type="text"
                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="Rechercher"
              />
            </span>
          </div>
        </div>

        <div style="display: flex; flex-direction: column">
          <div>
            <div style="display: flex">
              <div>
                <span class="p-input-icon-left">
                  <i style="color: #000" class="pi pi-plus"></i>
                  <input
                    style="
                      padding-left: 30px;
                      border-radius: 10px;
                      background-color: #ffd700;
                      color: #000;
                      margin-right: 5px;
                    "
                    class="h-button"
                    type="button"
                    value="Ajouter un beneficiaire"
                    (click)="openAddOneBeneficiaire()"
                  />
                </span>
              </div>
              <div>
                <input
                  style="display: none"
                  type="file"
                  value="Ajouter"
                  class="file-input"
                  (change)="onFileChange($event)"
                  #fileUpload
                  accept=".csv"
                />
                <button
                  type="button"
                  class="btn-upload"
                  pButton
                  icon="pi pi-upload"
                  (click)="fileUpload.click()"
                  label="Importer via un fichier (.csv)"
                  style="padding-top: 10px; padding-bottom: 10px"
                ></button>
                <div
                  style="
                    text-align: center;
                    font-size: small;
                    color: blue;
                    text-decoration: underline;
                    padding: 5px;
                    cursor: pointer;
                  "
                  (click)="downloadCsv()"
                >
                  Aperçu un modèle de fichier csv
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Message d'erreur-->
      <div
        style="
          display: flex;
          width: 100%;
          justify-content: center;
          padding: 20px 0;
        "
      >
        <div *ngIf="status === 'loading'" class="my-loader">
          <mat-spinner
            [diameter]="50"
            mode="indeterminate"
            strokeWidth="4"
          ></mat-spinner>
        </div>

        <div *ngIf="status === 'failed'" class="my-loader">
          <span>Fichier non conforme</span>
        </div>

        <div *ngIf="status === 'none'" class="my-loader">
          <span>Aucun Fichier Sélectionné</span>
        </div>

        <div *ngIf="status === 'empty'" class="my-loader">
          <span>Fichier vide</span>
        </div>

        <div *ngIf="status === 'error_email'" class="my-loader">
          <span>Erreur, vérifiez les emails des bénéficiaires</span>
        </div>

        <div *ngIf="status === 'error_doublon_email'" class="my-loader">
          <span>Erreur doublon, vérifiez les emails des bénéficiaires</span>
        </div>

        <div *ngIf="status === 'error_user_already_exist'" class="my-loader">
          <span
            >Erreur, un ou plusieurs bénéficiaires existent déjà vérifiez votre
            liste</span
          >
        </div>

        <div *ngIf="status === 'error_date'" class="my-loader">
          <span
            >Erreur, vérifiez les dates de naissances des bénéficiaires</span
          >
        </div>

        <div *ngIf="status === 'error_mineur'" class="my-loader">
          <span
            >Erreur, votre fichier comprend au moins un mineur de moins de 15
            ans</span
          >
        </div>

        <div *ngIf="status === 'error_civilite'" class="my-loader">
          <span>Erreur, vérifiez les civilités des bénéficiaires</span>
        </div>

        <div *ngIf="status === 'error_nom'" class="my-loader">
          <span>Erreur, vérifiez les noms des bénéficiaires</span>
        </div>

        <div *ngIf="status === 'error_prenoms'" class="my-loader">
          <span>Erreur, vérifiez les prénoms des bénéficiaires</span>
        </div>
      </div>

      <!--Tableau-->
      <div>
        <p-table
          [value]="beneficiaires"
          dataKey="id"
          editMode="row"
          #dt1
          [showCurrentPageReport]="true"
          [rowHover]="true"
          [globalFilterFields]="['nom', 'prenom', 'email']"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Nom</th>
              <th>Prenom</th>
              <th colspan="2">Email</th>
              <th>Statut</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-beneficiaire>
            <tr>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="text"
                      [(ngModel)]="beneficiaire.nom"
                      required
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ beneficiaire.nom }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td class="ellipsis">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="text"
                      [(ngModel)]="beneficiaire.prenom"
                      required
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ beneficiaire.prenom }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td colspan="2" class="ellipsis">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="text"
                      [(ngModel)]="beneficiaire.email"
                      required
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ beneficiaire.email }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <i
                  *ngIf="beneficiaire.status == 'pending'"
                  class="fa fa-check-circle inactive"
                  aria-hidden="true"
                ></i>
                <i
                  *ngIf="beneficiaire.status == 'activate'"
                  class="fa fa-check-circle active"
                  aria-hidden="true"
                ></i>
                <i
                  *ngIf="beneficiaire.status == 'blocked'"
                  class="fa fa-check-circle deactive"
                  aria-hidden="true"
                ></i>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer"> </ng-template>
        </p-table>
      </div>
    </div>
  </section>
</main>

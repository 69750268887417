import { StepModel } from "./step.model";
import { AttributionUserModel } from "./attribution-user.model";

export class CommandeModel {
  public numero: string;
  public date: Date;
  public dateExecution: Date;
  public montant: number;
  public commission: number;
  public tauxCommission: number;
  public montantTotal: number;
  public organisationId: string;
  public organisationName: string;
  public organisationEmail: string;
  public professionnelEmail: string;
  public factureId: string;
  public factureStatus: string;
  public totalTickets: number;
  public csvUrl: string;
  public devisUrl: string;
  public factureUrl: string;
  public steps: StepModel[];
  public beneficiaires: AttributionUserModel[];

  constructor(
    numero: string,
    date: Date,
    dateExecution: Date,
    montant: number,
    commission: number,
    tauxCommission: number,
    montantTotal: number,
    organisationId: string,
    organisationName: string,
    organisationEmail: string,
    professionnelEmail: string,
    factureId: string,
    factureStatus: string,
    totalTickets: number,
    csvUrl: string,
    devisUrl: string,
    factureUrl: string,
    steps: StepModel[],
    beneficiaires: AttributionUserModel[]
  ) {
    this.numero = numero;
    this.date = date;
    this.dateExecution = dateExecution;
    this.montant = montant;
    this.commission = commission;
    this.tauxCommission = tauxCommission;
    this.montantTotal = montantTotal;
    this.organisationId = organisationId;
    this.organisationName = organisationName;
    this.organisationEmail = organisationEmail;
    this.professionnelEmail = professionnelEmail;
    this.factureId = factureId;
    this.factureStatus = factureStatus;
    this.totalTickets = totalTickets;
    this.csvUrl = csvUrl;
    this.devisUrl = devisUrl;
    this.factureUrl = factureUrl;
    this.steps = steps;
    this.beneficiaires = beneficiaires;
  }
}

import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { AttributionUserModel } from "src/app/data/model/attribution-user.model";
import { ConfirmationService, MessageService } from "primeng/api";

@Component({
  selector: "app-preview-attribution",
  templateUrl: "./preview-attribution.component.html",
  styleUrls: ["./preview-attribution.component.css"],
})
export class PreviewAttributionComponent implements OnInit {
  attributionsUsers: AttributionUserModel[];
  sommeTotale: number = 0;

  constructor(
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.sommeTotale = this.attributionsUsers.reduce(
      (total, user) => total + user.nombre_ticket * user.prix_unitaire,
      0
    );
  }

  addBeneficiaire() {
    const newAttribution = {
      id: 0,
      nom: "",
      prenoms: "",
      email: "",
      nombre_ticket: 0,
      prix_unitaire: 0,
    };
    this.attributionsUsers.unshift(newAttribution);
  }
  deleteItem(attribution: AttributionUserModel) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete " + attribution.email + "?",
      header: "Confirm",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.attributionsUsers = this.attributionsUsers.filter(
          (val) => val.email !== attribution.email
        );
        this.messageService.add({
          severity: "success",
          summary: "Successful",
          detail: "Product Deleted",
          life: 3000,
        });
      },
    });
  }
  onSommeChange() {
    this.sommeTotale = this.attributionsUsers.reduce(
      (total, user) => total + user.nombre_ticket * user.prix_unitaire,
      0
    );
  }
}

import { HttpHeaders } from "@angular/common/http";
import * as moment from "moment";

export let formatNumber = (price: string) => {
  if (price.toString().length > 3) {
    price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return price;
};

export function getAccessTokenFromCokie() {
  return document.cookie.split("; ").reduce((acc, curr) => {
    const pair = curr.split("=");
    acc[pair[0]] = pair[1];
    return acc;
  }, {});
}

export function checkDuplicateValues(_arrayItems: any) {
  let valueArr = _arrayItems.map(function (item) {
    return item.email;
  });
  return valueArr.some(function (item, idx) {
    return valueArr.indexOf(item) != idx;
  });
}

export function checkIfUsersInTheOrganisation(
  organisationUsers: any,
  users: any
) {
  let error = "";
  for (const beneficiaire of users) {
    if (!organisationUsers.some((e) => e.email === beneficiaire.email)) {
      error = "user_not_found";
      break;
    }
  }

  return error;
}

export function checkIfBeneficiairesInTheOrganisation(
  organisationBeneficiaires: any,
  beneficiaires: any
) {
  let error = "";
  for (const beneficiaire of beneficiaires) {
    if (organisationBeneficiaires.some((e) => e.email === beneficiaire.email)) {
      error = "user_found";
      break;
    }
  }

  return error;
}

export function checkIfBeneficiaireIsInTheOrganisation(
  organisationBeneficiaires: any,
  beneficiaires: any
) {
  let error = "";
  for (const beneficiaire of beneficiaires) {
    if (
      organisationBeneficiaires.some(
        (e) => e.email === beneficiaire.beneficiaireEmail
      )
    ) {
      error = "user_found";
      break;
    }
  }

  return error;
}

export function checkIfUserIsMineur(_dateNaissance: Date) {
  return moment(new Date()).diff(_dateNaissance, "years", false);
}

export function getHeadersWithAccessToken(): HttpHeaders {
  return new HttpHeaders({
    "Content-Type": "application/json",
    Authorization: "Bearer " + getAccessTokenFromCokie()["jwt"],
  });
}

export function getAccessToken() {
  return "Bearer " + getAccessTokenFromCokie()["jwt"];
}

export function getJwt() {
  return getAccessTokenFromCokie()["jwt"];
}

export function updateCookies(token: string) {
  if (token !== null || token !== undefined) {
    let now = new Date();
    let time = now.getTime();
    let expireTime = time + 1000 * 900;
    now.setTime(expireTime);

    document.cookie =
      "jwt=" + token + ";expires=" + now.toUTCString() + "; path=/";
  }
}

export function deleteAllCookies() {
  let cookies = document.cookie.split(";");

  for (const element of cookies) {
    let cookie = element;
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function searchByBool(value: boolean): string {
  if (value) {
    return "Payé";
  } else {
    return "Echec";
  }
}

export function createCookie(accessToken: string, expires: number) {
  let now = new Date();
  let time = now.getTime();
  let expireTime = time + expires * 1000;
  now.setTime(expireTime);

  document.cookie =
    "jwt=" + accessToken + ";expires=" + now.toUTCString() + "; path=/";
}

export function deconnexion() {
  localStorage.clear();
  localStorage.clear();
  deleteAllCookies();
}

export function isIncorrectPrice(price: number): boolean {
  return (
    price === null ||
    price === undefined ||
    !Number.isInteger(price) ||
    price <= 0
  );
}

export function isSmallerThan(price: number, limit: number): boolean {
  return price < limit;
}

export function isNotMultipleOf(price: number, multiple: number): boolean {
  return price % multiple !== 0;
}

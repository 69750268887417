import { HelpUseCase } from "./../../usecases/help-use-case";
import { OrganisationService } from "./../../services/organisation.service";
import { OrganisationEntity } from "./../../data/entity/organisation.entity";
import { ProfessionelEntity } from "./../../data/entity/professionnel.entity";
import { ProfessionelService } from "./../../services/professionel.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AideModel } from "src/app/data/model/aide.model";
import { Message } from "primeng/api";

@Component({
  selector: "app-aide",
  templateUrl: "./aide.component.html",
  styleUrls: ["./aide.component.css"],
})
export class AideComponent implements OnInit {
  user: ProfessionelEntity;
  organisation: OrganisationEntity;
  formAide: FormGroup;
  isLoadingSendData: boolean = false;
  isSendDataSuccessNotification: boolean = false;
  isSendDataFailedNotification: boolean = false;
  messages: Message[];
  @ViewChild("description", { static: false }) descriptionInput;
  @ViewChild("subject", { static: false }) subjectInput;

  constructor(
    private formBuilder: FormBuilder,
    private professionelService: ProfessionelService,
    private organisationService: OrganisationService,
    private helpUseCase: HelpUseCase
  ) {}

  ngOnInit() {
    if (this.professionelService.getProfessionnel().value == null) {
      this.professionelService.getLocalStorageProfessionnel();
    }

    if (this.organisationService.getOrganisation().value == null) {
      this.organisationService.getLocalStorageOrganisation();
    }

    this.professionelService
      .getProfessionnel()
      .subscribe((_data: ProfessionelEntity) => {
        this.user = _data;
      });

    this.organisationService
      .getOrganisation()
      .subscribe((_data: OrganisationEntity) => {
        this.organisation = _data;
      });

    this.formAide = this.formBuilder.group({
      email: [this.user ? this.user.email : "", [Validators.required]],
      organisation: [
        this.user ? this.organisation.organisationNom : "",
        [Validators.required],
      ],
      sujet: ["", [Validators.required]],
      description: ["", [Validators.required]],
    });
  }

  submitForm() {
    this.isLoadingSendData = true;
    this.isSendDataSuccessNotification = false;
    this.isSendDataFailedNotification = false;

    if (this.formAide.valid) {
      const formData = this.formAide.value;
      this.helpUseCase
        .execute(
          new AideModel(
            formData.email,
            formData.organisation,
            formData.sujet,
            formData.description
          )
        )
        .subscribe(
          (result) => {
            this.isLoadingSendData = false;
            this.isSendDataSuccessNotification = true;
            this.isSendDataFailedNotification = false;
            // this.formAide.reset();
            this.descriptionInput.nativeElement.value = "";
            this.subjectInput.nativeElement.value = "";
            this.messages = [
              {
                severity: "success",
                summary: "",
                detail: "Message envoyé avec succès...",
              },
            ];
          },
          (err: any) => {
            this.isLoadingSendData = false;
            this.isSendDataFailedNotification = true;
            this.isSendDataSuccessNotification = false;
            this.messages = [
              {
                severity: "error",
                summary: "",
                detail: "Message non envoyé...",
              },
            ];
          }
        );
    } else {
      this.isLoadingSendData = false;
      this.validateAllFormFields(this.formAide);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}

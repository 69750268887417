import { conf } from "./../../../config/config";
import { BeneficiaireModel } from "./../../model/beneficiaire.model";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  getAccessTokenFromCokie,
  getHeadersWithAccessToken,
} from "src/app/helpers/helpers";

@Injectable({
  providedIn: "root",
})
export class DashboardRDS {
  constructor(private http: HttpClient) {}

  getData(organisationId: string): Observable<BeneficiaireModel[]> {
    return this.http.get<BeneficiaireModel[]>(
      conf.ipAddr +
        "/samafils/v1/samafils/beneficiaire/list?id=" +
        organisationId,
      { headers: getHeadersWithAccessToken() }
    );
  }
}

import { BeneficiaireEntity } from "../../data/entity/beneficiaire.entity";
import { Component, OnInit } from "@angular/core";
import { BeneficiaireService } from "src/app/services/beneficiaire.service";
import { DashboardUseCase } from "src/app/usecases/dashboard-use-case";
import { ProfessionelService } from "src/app/services/professionel.service";
import { csvToJson } from "csvtojson-converter";
import { FileService } from "../../services/file.service";
import { MessageService } from "primeng/api";
import {
  checkDuplicateValues,
  checkIfBeneficiairesInTheOrganisation,
  checkIfUserIsMineur,
} from "../../helpers/helpers";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { LoadedBeneficiairePopupComponent } from "./components/loaded-beneficiaire-popup/loaded-beneficiaire-popup.component";
import { OneBeneficiairePopupComponent } from "./components/one-beneficiaire-popup/one-beneficiaire-popup.component";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "app-beneficiaire",
  templateUrl: "./beneficiaire.component.html",
  styleUrls: ["./beneficiaire.component.css"],
})
export class BeneficiaireComponent implements OnInit {
  beneficiaires: BeneficiaireEntity[];
  isLoadingBeneficiaires: boolean;
  donneesCSV: any[];
  status: string;
  civilites: string[] = ["Monsieur", "Madame"];
  regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  regex_date = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
  refAddOne: DynamicDialogRef;
  refAddMultiple: DynamicDialogRef;

  constructor(
    private beneficiaireService: BeneficiaireService,
    private dashboardUseCase: DashboardUseCase,
    private professionelService: ProfessionelService,
    private fileService: FileService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isLoadingBeneficiaires = true;

    console.log("isLoadingBeneficiaires begin...");
    console.log(this.isLoadingBeneficiaires);

    if (this.professionelService.getProfessionnel().value == null) {
      this.professionelService.getLocalStorageProfessionnel();
    }

    // const professionel = JSON.parse(localStorage.getItem("professionnel"));
    // console.log(professionel);

    this.dashboardUseCase
      .execute(this.professionelService.getProfessionnel().value.organisationId)
      .subscribe(
        (_data) => {
          console.log("data loaded...");
          console.log(_data);
          this.beneficiaires = _data;
          this.isLoadingBeneficiaires = false;
        },
        (err: any) => {
          this.beneficiaires = [];
          this.isLoadingBeneficiaires = false;
          console.log(err);
        }
      );

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params["page"] == "add") {
        this.openAddOneBeneficiaire();
      }
    });

    console.log("isLoadingBeneficiaires end...");
    console.log(this.isLoadingBeneficiaires);
  }

  public onFileChange(event): void {
    this.status = "loading";

    const target: DataTransfer = <DataTransfer>event.target;

    if (target.files.length > 0) {
      const reader: FileReader = new FileReader();
      reader.readAsText(target.files[0]);

      reader.onload = (e: any) => {
        const delimiter = ",";
        this.donneesCSV = csvToJson(reader.result, delimiter);

        if (this.checkFile() === "loaded") {
          this.refAddMultiple = this.dialogService.open(
            LoadedBeneficiairePopupComponent,
            {
              header: "Ajout des bénéficiaires",
              data: this.donneesCSV,
              width: "80%",
              contentStyle: { overflow: "auto" },
              baseZIndex: 10000,
            }
          );

          this.refAddMultiple.onClose.subscribe((data) => {
            event.target.value = null;
            if (data != undefined) {
              this.messageService.add({
                severity: data.type,
                summary: data.summary,
                detail: data.message,
              });
              if (data.type === "success") {
                this.refreshList();
              }
            }
          });
        }
      };
    }
    event.target.value = null;
  }

  public openAddOneBeneficiaire() {
    this.refAddOne = this.dialogService.open(OneBeneficiairePopupComponent, {
      header: "Nouveau bénéficiaire",
      width: "80%",
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
    });

    this.refAddOne.onClose.subscribe((data) => {
      if (data != undefined) {
        this.messageService.add({
          severity: data.type,
          summary: data.summary,
          detail: data.message,
        });
      }
      this.refreshList();
    });
  }

  checkFile() {
    if (this.donneesCSV.length !== 0) {
      if (
        Object.keys(this.donneesCSV[0]).includes("civilite") &&
        Object.keys(this.donneesCSV[0]).includes("nom") &&
        Object.keys(this.donneesCSV[0]).includes("prenoms") &&
        Object.keys(this.donneesCSV[0]).includes("email")
      ) {
        this.dashboardUseCase.execute(
          this.professionelService.getProfessionnel().value.organisationId
        );
        this.beneficiaireService
          .getBeneficiaires()
          .subscribe((_data: BeneficiaireEntity[]) => {
            this.beneficiaires = _data;
          });

        for (const element of this.donneesCSV) {
          if (
            element.civilite === null ||
            element.civilite === undefined ||
            element.civilite.trim() === "" ||
            !this.civilites.includes(element.civilite)
          ) {
            this.status = "error_civilite";
            break;
          }

          if (
            element.nom === null ||
            element.nom === undefined ||
            element.nom.trim() === ""
          ) {
            this.status = "error_nom";
            break;
          }

          if (
            element.prenoms === null ||
            element.prenoms === undefined ||
            element.prenoms.trim() === ""
          ) {
            this.status = "error_prenoms";
            break;
          }

          if (
            element.email === null ||
            element.email === undefined ||
            element.email.trim() === "" ||
            !this.regex.test(element.email)
          ) {
            this.status = "error_email";
            break;
          }
        }

        if (checkDuplicateValues(this.donneesCSV)) {
          this.status = "error_doublon_email";
        }

        if (
          checkIfBeneficiairesInTheOrganisation(
            this.beneficiaires,
            this.donneesCSV
          ) === "user_found"
        ) {
          this.status = "error_user_already_exist";
        }
      } else {
        this.status = "failed";
      }
    } else {
      this.status = "empty";
    }

    this.status = this.status === "loading" ? "loaded" : this.status;

    return this.status;
  }

  refreshList() {
    this.isLoadingBeneficiaires = true;

    this.dashboardUseCase
      .execute(this.professionelService.getProfessionnel().value.organisationId)
      .subscribe(
        (_data) => {
          this.isLoadingBeneficiaires = false;
        },
        (err: any) => {
          this.isLoadingBeneficiaires = false;
        }
      );

    this.beneficiaireService
      .getBeneficiaires()
      .subscribe((_data: BeneficiaireEntity[]) => {
        this.beneficiaires = _data;
        this.isLoadingBeneficiaires = false;
      });
  }

  downloadCsv() {
    this.fileService.downloadCsvModelBeneficiaire();
  }
}

<p-confirmDialog [style]="{ width: '40vw' }" key="confirmSaveAllDialog"></p-confirmDialog>

<div *ngIf="isSavingBeneficiaires"
  style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      "
>
  <mat-spinner mode="indeterminate" strokeWidth="5"></mat-spinner>
</div>

<div *ngIf="!isSavingBeneficiaires">
  <p-table [value]="beneficiaires" responsiveLayout="scroll" [paginator]="true" [rows]="7" [responsive]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>Civilite</th>
        <th pSortableColumn="nom">Nom <p-sortIcon field="nom"></p-sortIcon> </th>
        <th pSortableColumn="prenom">Prenoms <p-sortIcon field="prenom"></p-sortIcon></th>
        <th pSortableColumn="email">Email <p-sortIcon field="email"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-beneficiaire>
      <tr>
        <td>{{ beneficiaire.civilite }}</td>
        <td>{{ beneficiaire.nom }}</td>
        <td>{{ beneficiaire.prenoms }}</td>
        <td>{{ beneficiaire.email }}</td>
      </tr>
    </ng-template>
  </p-table>

  <div style="display: flex; width: 100%; justify-content: flex-end; padding-top: 10px">
    <button
      class="btn-annuler"
      type="button"
      pButton
      label="Annuler"
      (click)="handleClose()"
    ></button>

    <button
      style="margin-left: 15px"
      class="btn-valider"
      type="button"
      pButton
      label="Valider"
      (click)="handleValider()"
    ></button>
  </div>
</div>


<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast key="success_commande"></p-toast>
<p-toast key="error_commande"></p-toast>

<app-header></app-header>
<main>
  <app-navigation [selectedItem]="2"></app-navigation>
  <section class="section">
    <div class="title"><h1>Hatwork Account Manager</h1></div>
    <div
      *ngIf="isLoading"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      "
    >
      <mat-spinner
        mode="indeterminate"
        strokeWidth="5"
        [diameter]="50"
      ></mat-spinner>
    </div>
    <div
      *ngIf="!isLoading"
      class="other-body"
      style="flex-direction: column; justify-content: flex-start"
    >
      <div style="display: flex; width: 100%; justify-content: space-between">
        <div>
          <div class="flex">
            <!-- <span class="p-input-icon-right">
              <i class="pi pi-search" style="margin-top: -12px"></i>
              <input
                pInputText
                type="text"
                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="Rechercher"
              />
            </span> -->
            <div class="libelle" style="font-size: 11px !important">
              DATE D'EXECUTION
            </div>
            <div>
              <input
                type="date"
                [class]="isValid"
                id="date_execution"
                name="date_execution"
                [min]="minDate"
                [(ngModel)]="dateExecution"
              />
            </div>
          </div>
        </div>
        <div style="display: flex; flex-direction: column">
          <div>
            <div style="display: flex">
              <div>
                <span class="p-input-icon-left">
                  <i style="color: #000" class="pi pi-plus"></i>
                  <input
                    (click)="addBeneficiaire()"
                    style="
                      padding-left: 30px;
                      border-radius: 10px;
                      background-color: #ffd700;
                      color: #000;
                      margin-right: 5px;
                    "
                    class="h-button"
                    type="button"
                    value="Commande unitaire"
                  />
                </span>
              </div>
              <div>
                <input
                  style="display: none"
                  type="file"
                  value="Ajouter"
                  class="file-input"
                  (change)="onFileChange($event)"
                  #fileUpload
                  accept=".csv"
                />
                <button
                  type="button"
                  class="btn-upload"
                  pButton
                  icon="pi pi-upload"
                  (click)="fileUpload.click()"
                  label="Importer via un fichier (.csv)"
                  style="padding-top: 10px; padding-bottom: 10px"
                ></button>
                <div
                  style="
                    text-align: center;
                    font-size: small;
                    color: blue;
                    text-decoration: underline;
                    padding: 5px;
                    cursor: pointer;
                  "
                  (click)="downloadCsv()"
                >
                  Aperçu un modèle de fichier csv
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Message d'erreur-->
      <div *ngIf="status === 'empty'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <div *ngIf="status === 'error_prix_unitaire'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <div *ngIf="status === 'failed'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <div *ngIf="status === 'user_not_found'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <div *ngIf="status === 'error_nom'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <div *ngIf="status === 'error_prenoms'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <div *ngIf="status === 'error_nombre_ticket'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <div *ngIf="status === 'not_valid'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <div *ngIf="status === 'error_doublon_email'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <div *ngIf="status === 'error_prix_unitaire_minimum'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <div *ngIf="status === 'error_prix_unitaire_multiple'" class="my-loader">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
        ></p-messages>
      </div>

      <!--Message d'erreur-->

      <div>
        <p-table
          [value]="attributionsUsers"
          dataKey="id"
          editMode="row"
          #dt1
          [showCurrentPageReport]="true"
          [rowHover]="true"
          [globalFilterFields]="['nom', 'prenoms', 'email']"
        >
          <!-- <ng-template pTemplate="caption">
            <div class="flex">
              <span class="p-input-icon-left ml-auto" style="margin-right: 10px">
                  <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Rechercher" />
              </span>
              <span class="p-input-icon-left">
                  <i style="color: white" class="pi pi-plus"></i>
                  <input (click)="addBeneficiaire()" style="padding-left: 30px" class="h-button" type="button" value="Ajouter">
              </span>
            </div>
          </ng-template> -->
          <ng-template pTemplate="header">
            <tr>
              <th>Nom</th>
              <th>Prenom</th>
              <th colspan="2">Email</th>
              <th>Nombre Tickets</th>
              <th style="width: 7rem"></th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-beneficiaire
            let-editing="editing"
            let-ri="rowIndex"
          >
            <tr [pEditableRow]="beneficiaire">
              <td
                [pEditableColumn]="beneficiaire.nom"
                pEditableColumnField="nom"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="text"
                      [(ngModel)]="beneficiaire.nom"
                      required
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ beneficiaire.nom }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td
                class="ellipsis"
                [pEditableColumn]="beneficiaire.prenoms"
                pEditableColumnField="prenoms"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="text"
                      [(ngModel)]="beneficiaire.prenoms"
                      required
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ beneficiaire.prenoms }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td
                class="ellipsis"
                [pEditableColumn]="beneficiaire.email"
                pEditableColumnField="email"
                colspan="2"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="text"
                      [(ngModel)]="beneficiaire.email"
                      required
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ beneficiaire.email }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td
                [pEditableColumn]="beneficiaire.nombre_ticket"
                pEditableColumnField="nombre_ticket"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="number"
                      [(ngModel)]="beneficiaire.nombre_ticket"
                      (ngModelChange)="onSommeChange()"
                      (keydown.tab)="onEndTabAddRow()"
                      required
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ beneficiaire.nombre_ticket }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <!-- <td
                [pEditableColumn]="beneficiaire.prix_unitaire"
                pEditableColumnField="prix_unitaire"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="number"
                      [(ngModel)]="beneficiaire.prix_unitaire"
                      (ngModelChange)="
                        onSommeChange(beneficiaire.prix_unitaire)
                      "
                      (keydown.tab)="onEndTabAddRow()"
                      min="500"
                      required
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ beneficiaire.prix_unitaire | formatNumber }}
                  </ng-template>
                </p-cellEditor>
              </td> -->
              <td>
                <button
                  *ngIf="!editing"
                  pButton
                  pRipple
                  type="button"
                  pInitEditableRow
                  icon="pi pi-pencil"
                  (click)="onRowEditInit(beneficiaire)"
                  class="p-button-rounded p-button-text p-button-info"
                ></button>
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  type="button"
                  pSaveEditableRow
                  icon="pi pi-check"
                  (click)="onRowEditSave(beneficiaire)"
                  class="p-button-rounded p-button-text p-button-success mr-2"
                ></button>
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  type="button"
                  pCancelEditableRow
                  icon="pi pi-times"
                  (click)="onRowEditCancel(beneficiaire, ri)"
                  class="p-button-rounded p-button-text p-button-danger"
                ></button>
                <button
                  *ngIf="!editing"
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-trash"
                  (click)="deleteItem(beneficiaire)"
                  class="p-button-rounded p-button-text p-button-danger"
                ></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr id="ticket">
              <td colspan="4" class="text-right">Valeur unitaire du ticket</td>
              <td
                [pEditableColumn]="prix_unitaire"
                pEditableColumnField="prix_unitaire"
                colspan="2"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="number"
                      [(ngModel)]="prix_unitaire"
                      (ngModelChange)="onSommeChange(prix_unitaire)"
                      min="1500"
                      required
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ prix_unitaire | formatNumber }}
                  </ng-template>
                </p-cellEditor>
              </td>
            </tr>
            <tr>
              <td colspan="4" class="text-right">Montant à récharger</td>
              <td colspan="2">{{ sommeTotale | formatNumber }}</td>
            </tr>
            <tr>
              <td colspan="4" class="text-right">Commission appliquée</td>
              <td colspan="2">
                {{ getCommission(sommeTotale) | formatNumber }}
              </td>
            </tr>
            <tr>
              <td colspan="4" class="text-right">Total à payer</td>
              <td colspan="2">
                {{ getMontantPlusCommission(sommeTotale) | formatNumber }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div style="display: flex; width: 100%; justify-content: flex-end">
        <button
          class="btn-valider"
          type="button"
          pButton
          label="Valider"
          (click)="runCommande()"
        ></button>
      </div>
      <div>
        <div *ngIf="status == 'loading'" class="my-loader">
          <mat-spinner mode="indeterminate" strokeWidth="2"></mat-spinner>
        </div>

        <!-- <div *ngIf="status === 'none'" class="my-loader">
          <span>Aucun Fichier Sélectionné</span>
        </div>

        <div *ngIf="status === 'oneEmpty'" class="my-loader">
          <span>Erreur, un bénéficiaire vide est dans le tableau</span>
        </div>

        <div *ngIf="status === 'error_email'" class="my-loader">
          <span>Erreur, vérifiez les emails des bénéficiaires</span>
        </div> -->

        <!-- <div *ngIf="status === 'error_doublon_email'" class="my-loader">
          <span>Erreur doublon, vérifiez les emails des bénéficiaires</span>
        </div> -->

        <!-- <div *ngIf="status === 'error_prix_unitaire'" class="my-loader">
          <span
            >Erreur, vérifiez les prix unitaires des tickets attribués des
            bénéficiaires</span
          >
        </div> -->

        <!-- <div
          *ngIf="status === 'error_prix_unitaire_multiple'"
          class="my-loader"
        >
          <span
            >Erreur, les prix unitaires des tickets doivent être un multiple de
            100</span
          >
        </div> -->

        <!-- <div *ngIf="status === 'error_prix_unitaire_minimum'" class="my-loader">
          <span
            >Erreur, le montant des prix unitaires doit être minimum 500</span
          >
        </div> -->

        <!-- <div *ngIf="status === 'user_not_found'" class="my-loader">
          <span
            >Erreur, Un bénéficiaire non répertorié dans l'organisation est a
            été détecté</span
          >
        </div> -->
      </div>
    </div>
  </section>
</main>
